.Container{
    height: calc(100% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.content_box {
    margin-top: 10px;
    padding: 5px 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: .05rem;
    }
    &::-webkit-scrollbar-track {
        background-color: #e8e9ee;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #FFF;
        border-radius: 10px;
    }
    .empty_wrap{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .model_divider{
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 14px;
        margin-bottom: 4px;
    }
    .model_list{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .softedge_item {
        position: relative;
        cursor: pointer;
        display: inline-block;
        margin: 12px 7.5px 0 7.5px;
        width: calc((100% - 60px)/4);
        height: auto;
        background-color: #e8e9ee;
        &::after{
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
        }
        border-radius: 10px;
        overflow: hidden;
        .softedge_item_img{
            position: absolute;
            width: 100%;
            height: 100%;
        }
        .softedge_styl {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
        &.softedge_hover:hover {
            opacity: .5;
        }
    }
}
.pack_up {
    padding: 0 15px;
    margin-top: 10px;
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span:first-child{
        font-size: 12px;
        font-weight: 700;
    }
    .pack_up_text {
        cursor: pointer;
        padding: 2px 10px;
        color: #35415C;
        border-radius: 20px;
        display: inline-block;
        &:hover{
            background: #EFF2FD;
            color: #2237F4;
        }
    }
}

.action_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}