.crop {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        max-width: 100%;
    }
    .crop_ReactCrop{
        margin-bottom: 20px;
    }
}
