.AiEdit {
    padding: 15px;
    padding-left: 0;
    display: flex;
    .ant-menu {
        height: 100%;
    }
    .edit_cont {
        display: flex;
        flex-direction: row;
        flex: 1;
        .edit_center {
            position: relative;
            width: 300px;
            padding: 1px 0;
            &.disabled{
                opacity: .8;
                filter: grayscale(100%);
            }
            .shift_tip {
                align-items: flex-start;
                width: 100%;
                margin-left: 0;
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 20px;
            }
            .edit_center_{
                .tip {
                    font-weight: 800;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color:#1d1b1b;
                    font-size:14px;
                    i{
                        display: inline-block;
                        width: 13px;
                        height: 13px;
                        margin-right: 10px;
                        background: center / contain no-repeat;
                    }
                }
            }
            .rcslider {
                margin-bottom: 18px;
                p {
                    font-size: 12px;
                    color: #1d1b1b;
                    span:nth-child(2) {
                        float: right;
                    }
                }
                .ant-slider{
                    margin-top: 5px;
                }
            }
            .beauty_btn {
                width: 100%;
                height: 35px;
                line-height: 35px;
                border-radius: 12px;
                text-align: center;
                font-size: 16px;
                cursor: pointer;
                margin: 30px 0;
            }
        }
        .edit_right {
            position: relative;
            padding: 40px 20px 20px 20px;
            flex: 1;
            background-color: #F5F6FC;
            border-radius:16px;
            display: flex;
            overflow: hidden;
            &::-webkit-scrollbar {
                width: .05rem;
            }
            &::-webkit-scrollbar-track {
                background-color: #fff;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #e8e9ee;
                border-radius: 10px;
            }
            .edit_right_c {
                flex: 1;
                display: flex;
                justify-content: center;
                margin: 0px auto;
                // overflow: hidden;
                position: relative;
                .img_box {
                    width: 100%;
                    height: 80%;
                    background-color: #000;
                }
                .wrap_box{
                    position: absolute;
                    left: 50%;
                    top: 0;
                    transform: translate(-50%, 0);
                    height: 100%;
                    padding-right: 120px;
                }
                .wrap_box_ {
                    padding-left: 150px;
                }
                .dragAndScale_box {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 140px;
                    p {
                        margin-top: 10px;
                        color: #979797;
                    }
                }
            }
        }
    }

    .ImgComparer {
        position: relative;
        [data-testid="container"] {
            display: flex!important;
            // justify-content: center;
            // align-items: center;
        }
        img {
            max-width: 800%!important;
        }
        .dragBartip {
            position: absolute;
            display: flex;
            top: -31px;
            visibility: hidden;
            img {
                width: 48px;
                height: 26px;
            }
            img:nth-child(1) {
                margin-right: 10px;
            }
           
        }
    }
}

.operation_btn {
    height: 35px;
    line-height: 35px;
    width: 100%;
    border-radius: 8px;
    margin-top: 70px;
    text-align: center;
    cursor: pointer;
}

.aibeauty {
    .ant-segmented {
        width: 100%;
    }
    .ant-segmented-item {
        width: 50%;
    }
    .edit_center_ {
        padding: 40px 5px 0 5px;
    }
}

