.oneTimePay {
    width: 100%;
    .title {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        min-width: 80%;
    }
    .pay_box {
        display: flex;
        justify-content:space-between;
        margin-bottom: .2rem;
        .pay_box_item {
            cursor: pointer;
            background-color: #fff;
            width: calc(94%/3);
            padding: .3rem;
            border-radius: 12px;
            display: flex;
            background: url('~@assets/images/payBg.svg') center / cover no-repeat;
            .pay_left {
                width: calc(100% - 100px);
                .count_box {
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    width: 120px;
                    padding: 7px 15px;
                    color: #fff;
                    background-color: #6C83FF;
                    border-radius: 8px;
                    img {
                        margin-right: 10px;
                        width: 16px;
                        height: 16px;
                    }
                }
                .tip {
                    margin-top: 10px;
                    color: #515275;
                }
            }
            .pay_right {
                width: 100px;
                color: #515275;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                    font-size: 30px;
                    font-weight: bold;
                    span {
                        font-size: 16px;
                    }
                }
            }





            .span_1 {
                padding: 5px 10px;
                color: #A7B6FF;
                background-color: #EFF2FD;
                font-size: 10px;
                border-radius: 30px;
            }
            .span_2 {
                display: inline-block;
                color: #fff;
                background: url('~@assets/images/Personal Center_Energy_Tag.svg') center / cover no-repeat;
                width: 1.3rem;
                height: .4rem;
                position: absolute;
                top: 0;
                right: 0;
                text-align: center;
                line-height: .3rem;
            }
            .p_1 {
                margin-top: .1rem;
            }
            .p_2 {
                color: #2237F4;
                font-size: 12px;
            }
            .span_3 {
                background: url('~@assets/images/energy point.svg') center / cover no-repeat;
                display: inline-block;
                width: .25rem;
                height: .24rem;
            }
            .span_4 {
                color: #2237F4;
                font-size: .5rem;
                font-weight: bold;
                margin-left: 10px;
            }
            .span_5 {
                display: flex;
                align-items: center;
                color: #2237F4;
                padding: .1rem .2rem;
                background-color: #EBF0FF;
                border-radius: 30px;
                float: right;
                margin-top: .25rem;
                transition: all 0.3s;
                span {
                    display: inline-block;
                    width: .2rem;
                    height: .15rem;
                    background: url('~@assets/images/Group 408.svg') center / cover no-repeat;
                    margin-right: 5px;
                    margin-top: 2px;
                }
                &:hover {
                    background-color: var(--color-primary);
                    color: #fff;
                    cursor: pointer;
                }
                &:hover span{
                    background: url('~@assets/images/Group 408 (1).svg') center / cover no-repeat;
                }
            }
            :global(.ant-divider-horizontal) {
                margin: .1rem 0 .1rem 0
            }
        }
        .pay_box_item_border {
            box-shadow: 0 0 10px #d8d9e1;
        }
    }
}