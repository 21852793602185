.Container{
    display: flex;
    flex-wrap: wrap;
    // width: 352px;
    max-width: 352px;
    min-width: 80px;
    padding: 5px 5px 16px 5px;
    padding-bottom: 0;
}

.Item{
    cursor: pointer;
    position: relative;
    width: 64px;
    height: auto;
    box-sizing: border-box;
    overflow: hidden;
    vertical-align: bottom;
    border-radius: 8px;
    margin-bottom: 4px;
    &::after{
        content: '';
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }
    &:not(:nth-child(5n+1)){
        margin-left: 4px;
    }
    &.tryOn::after{
        padding-bottom: 133.14%;
    }
    img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    p{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px;
        background-color: rgba(0, 0, 0, 0.3);
        color: #fff;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        span{
            display: inline-block;
            width: 90%;
            height: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.Item_selected{
    border: 2px solid var(--color-primary);
}