.Container{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 74px 20px 45px;
}

.Loading{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .6);
}

.Tip_float{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 22px;
    font-size: 14px;
    white-space: nowrap;
    height: 28px;
    line-height: 28px;
    border-radius: 12px;
    background-color: rgba(235, 240, 255, 1);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    &::before{
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        background: url("~@assets/images/icon_warning.svg") center / cover no-repeat;
        margin-right: 16px;
    }
}

.UploadWrap{
    display: inline-block;
    width: 100%;
    height: 100%;
    :global(.ant-upload.ant-upload-drag){
        display: inline-block;
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        text-align: left;
    }
    :global(.ant-upload.ant-upload-btn){
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 0;
    }
    :global(.ant-upload-drag .ant-upload-drag-container){
        display: inline-block;
        width: 100%;
        height: 100%;
    }
    :global(.ant-upload-drag .ant-upload){
        padding: 0;
    }
}