.tag_styl {
    margin-top: 16px;
    padding: 0 8px;
    display: flex;
    height: 28px;
    .tag_title {
        width: 40px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
    }
    .tag_box {
        cursor: pointer;
        background-color: #EFF2FD;
        width: calc(100% - 40px);
        line-height: 28px;
        border-radius: 30px;
        padding-left: 10px;
        :global(.ant-tag){
            border: 0;
            border-radius: 12px
        }
    }
    .tag_create {
        display: inline-block;
        float: right;
        height: 28px;
        cursor: pointer;
        width: 60px;
        background-color: var(--color-primary);
        border-radius: 0 30px 30px 0;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 16px;
            width: 16px;
            transition: all 0.2s ease-in-out;
        }
        .tag_img {
            transform: rotate(90deg);
            transition: transform 0.2s ease-in-out;
        }
    }
}
.feature_content {
    width: 328px;
    background-color: #F8F9FF;
    box-shadow: 0px 8px 8px 0px #00000033;
    padding: 20px 12px 10px 14px;
    border-radius: 12px;
}