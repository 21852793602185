.Container{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    .LeftComp{
        flex: none;
        width: 360px;
    }
    .MiddleComp{
        position: relative;
        flex: 1;
        min-width: 450px;
        padding-right: 235px;
        overflow: hidden;
        &.hidden{
            padding-right: 0;
        }
    }
    .RightComp{
        position: absolute;
        right: 0;
        width: 235px;
        height: 100%;
        border-radius: 12px;
        overflow: hidden;
        // &.clothes{
        //     padding-top: 44px;
        //     border-top-left-radius: 0;
        //     border-bottom-left-radius: 0;
        // }
        &.hidden{
            width: 0;
        }
    }
    
}