.bindWechatModal_body {
    width: 200px;
    margin: 20px auto 0;
    p {
        margin-top: 10px;
        text-align: center;
    }
    >div, img{
        width: 200px;
        height: 200px;
    }
    >div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}