.LeftMenuIcon{
    display: block;
    width: 24px;
    height: 24px;
    background: center / cover no-repeat;
    &.Icon_light{ background-image: url('~@assets/images/icon_light.svg'); }
    &.Icon_beautify{ background-image: url('~@assets/images/icon_beautify.svg'); }
    &.Icon_magicErase{ background-image: url('~@assets/images/icon_magicErase.svg'); }
    &.Icon_imageUpgrade{ background-image: url('~@assets/images/icon_imageUpgrade.svg'); }
    &.Icon_shapeShift{ background-image: url('~@assets/images/icon_shapeShift.svg'); }
}

.LeftMenu{
    width: 136px;
    flex: none;
    &:global(.ant-menu-inline .ant-menu-item){
        position: relative;
        width: 100%;
        margin: 0;
        border-radius: 0;
        padding-left: 22px!important;
        &:global(.ant-menu-item-selected){
            &::before{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: 3px;
                height: 100%;
                background-color: var(--color-primary);
            }
            .LeftMenuIcon{
                &.Icon_light{ background-image: url('~@assets/images/icon_light_actived.svg'); }
                &.Icon_beautify{ background-image: url('~@assets/images/icon_beautify_actived.svg'); }
                &.Icon_magicErase{ background-image: url('~@assets/images/icon_magicErase_actived.svg'); }
                &.Icon_imageUpgrade{ background-image: url('~@assets/images/icon_imageUpgrade_actived.svg'); }
                &.Icon_shapeShift{ background-image: url('~@assets/images/icon_shapeShift_actived.svg'); }
            }
        }
    }
    &:global(.ant-menu .ant-menu-item .ant-menu-item-icon +span){
        margin-inline-start: 8px
    }
}

.Spin{
    width: 100%;
    height: 100%;
    :global(.ant-spin-container){
        height: 100%;
    }
}