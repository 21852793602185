.head {
    flex: none;
    width: 72px;
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    border: 1px solid #e6e8f2;
    border-radius: 30px;
    &:hover {
        background-color: #f2f2f2;
    }
    .arrow {
        width: 9px;
        height: 9px;
        border-top: 2px solid rgba(29, 27, 27, 1);
        border-left: 2px solid rgba(29, 27, 27, 1);
        transform: rotate(-45deg);
        margin-right: 5px;
    }
    .back {
        font-size: 14px;
        line-height: 20px;
    }
}