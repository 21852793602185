

.Segmented{
    width: 640px;
    height: 36px;
    background:#EFF2FD;
    border-radius: 10px;
    margin: 0 auto;
    padding: 0;
    display: flex;
    align-items: center;
    :global(.ant-segmented-group){
        width: calc(100% - 6px);
        height: 30px;
        margin: 0 auto;
    }

    :global(.ant-segmented-item){
        // position: absolute;
        width: 33.3%;
        border-radius: 10px;
        &:nth-last-child(2){ left: 0; }
        &:nth-last-child(1){ right: 0; }
        &:active::after{ background-color: transparent!important; }
    }
    :global(.ant-segmented-item-selected){
        z-index: 1;
    }
    :global(.ant-segmented-item-label){
        line-height: 30px;
        height: 30px;
        border-radius: 10px;
        font-weight:500;
        color:#1d1b1b;
        font-size: 18px;
    }
    :global(.ant-segmented-thumb){
        border-radius:10px;
    }
}

.PlansList{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    // max-width: 1200px;
    margin: 20px auto;
    &.PlanType1{
        .PlanItem{
            width: calc((100% - .75rem) / 4);
        }
        .PlanItem:not(:last-child){
            margin-right: .25rem;
        }
    }
    &.PlanType2{
        .PlanItem{
            width: calc((100% - 1.5rem) / 3);
        }
        .PlanItem:not(:last-child){
            margin-right: .25rem;
        }
    }
}
.PlanItem_border {
    box-shadow: 0 0 10px #d8d9e1;
}
.PlanItem{
    position: relative;
    width: calc((100% - .75rem) / 4);
    max-width: 335px;
    background:#ffffff;
    border-radius: 16px;
    overflow: hidden;
    .PlanItemContent{
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}
.PlanItemContent_top {
    position: relative;
    width: 100%;
    color: #3D3E52;
    &::after{
        content: "";
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 43.56%;
    }
    &.top0 {
        background: url('~@assets/images/Member_01.svg') center / cover no-repeat;
    }
    &.top1 {
        background: url('~@assets/images/Member_02.svg') center / cover no-repeat;
    }
    &.top2 {
        background: url('~@assets/images/Member_03.svg') center / cover no-repeat;
    }
    &.top3 {
        background: url('~@assets/images/Member_04.svg') center / cover no-repeat;
    }
    >div{
        position: absolute;
        top: .3rem;
        left: .23rem;
    }
    p:nth-child(1) {
        font-size: .2rem;
        font-weight: bold;
    }
    p:nth-child(2) {
        font-size: .18rem;
    }
}
.PlanItemContent_content{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .PlanItemContent_ul {
        padding: 0 .3rem;
        li {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            color: #515275;
            font-size: 14px;
            line-height: 1.5;
            margin-top: .1rem;
            padding-left: .23rem;
            img {
                position: absolute;
                left: 0;
                top: .75em;
                transform: translate(0, -50%);
                width: .18rem;
            }
        }
    }
    
    .PlanItemContent_p {
        font-size: .16rem;
        color: #3D3E52;
        text-align: center;
        margin-top: .11rem;
        margin-bottom: .15rem;
        span {
            font-size: .30rem;
            font-weight: bold;
        }
    }
    
    .PlanItemContent_span {
        position: relative;
        display: block;
        width: 57%;
        margin: 0 auto .32rem;
        &::after{
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 28.125%;
        }
        >button{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            font-size: .17rem;
            background: #64A6FF!important;
            color: #fff;
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
        }
    }
}

