.Container{
    width: 235px;
    height: 100%;
    flex: none;
    background-color: #FFFFFF;
    padding: 13px 14px 13px 16px;
    display: flex;
    flex-direction: column;
}

.Title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    span:nth-child(2){
        cursor: pointer;
    }
}

.TaskListContainer{
    flex: 1;
    padding-right: 2px;
    margin-bottom: 20px;
    .TaskList {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        >div{
            margin-bottom: 14px;
        }
    }
    .TaskLoading{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .TaskEmpty{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}


.AllTasks{
    height: 32px;
    border-radius: 12px;
    font-size: 12px;
}

.Result{
    position: relative;
    width: 100%;
    &::after{
        content: '';
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }
}