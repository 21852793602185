.Container{
    width: 100%;
    height: 100%;
    padding-left: 4px;
    padding-right: 4px;
}

.UploadBox_right {
    width: 138px;
    height: 353px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    .UploadBox_right_ {
        height: 100%;
        width: 100%;
        background: url("~@assets/images/Group 712.svg") center / 80% no-repeat;

    }
}