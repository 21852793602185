.LeftBox{
    flex: 1;
    max-width: 352px;
    min-width: 300px;
    background: url('~@assets/images/login_left.webp') center / cover no-repeat;
    display: flex;
    justify-content: center;
    padding-top: 140px;
    overflow: hidden;
    transition: width .5s, min-width .5s;
    @media screen and (max-width: 760px) {
        flex: none;
        width: 0;
        min-width: 0;
    }
    >ul{
        max-width: 352px;
        min-width: 300px;
        height: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        li{
            font-size: 20px;
            line-height: 1;
            font-weight: 500;
            color: #FFFFFF;
        }
    }
}