.HelperTrigger{
    position: fixed;
    right: .6rem;
    bottom: 50px;
    z-index: 201;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    cursor: pointer;
    background: rgba(53, 65, 92, 1);
    font-size: 28px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
}

.HelperPopover{
    :global(.ant-popover-inner){
        background-color: transparent;
        box-shadow: none;
        padding: 0;
    }
}

.HelperContent{
    display: flex;
    align-items: flex-end;
}

.HelprQRCode{
    display: flex;
    // width: 134px;
    text-align: center;
    // margin-bottom: 14px;
    margin-right: 8px;
    padding: 8px;
    border-radius: 8px;
    background-color: #FFFFFF;
    overflow: hidden;
    >div:nth-child(1) {
        margin-right: 8px;
    }
    >div img{
        cursor: pointer;
        width: 120px;
        height: 120px;
    }
    >div p{
        height: 30px;
        background: rgba(53, 65, 92, 1);
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        color: #FFFFFF;
        border-radius: 0 0 8px 8px;
    }
}

.HelperList{
    background-color: #FFFFFF;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    margin: 0 auto;
    border-radius: 9px;
    padding: 10px;
    .HelperItem{
        cursor: pointer;
        width: 110px;
        height: 32px;
        border-radius: 8px;
        font-size: 14px;
        line-height: 29px;
        text-align: center;
        &.selected{
            background-color: var(--color-98);
        }
        &:not(:last-child){
            margin-bottom: 10px;
        }
    }
}

.GuideContent{
    width: 280px;
    height: 360px;
}

.FeedbackWrap{
    :global(.ant-modal){
        width: auto!important;
        min-width: 664px;
    }
    :global(.ant-modal-content){
        position: relative;
        padding: 0;
        border-radius: 10px;
        overflow: hidden;
    }
    :global(.ant-modal-close){
        width: 32px;
        height: 32px;
        top: 20px;
        right: 24px;
        transform: translate(0, -50%);
    }
    :global(.ant-modal-header){
        position: relative;
        width: 100%;
        height: 40px;
        background: transparent;
        margin-bottom: 0;
        :global(.ant-modal-title){
            position: absolute;
            left: 24px;
            top: 50%;
            transform: translate(0, -50%);
            height: 44px;
            line-height: 44px;
            color:#1d1b1b;
            font-size:16px;
        }
    }
    :global(.ant-modal-body){
        background: var(--color-98);
    }
}

.FeedbackContainer{
    padding: 24px;
    .FormContent{
        label{
            position: relative;
            font-size: 14px;
            line-height: 16px;
            span{
                position: absolute;
                transform: translate(0, 6px);
                color: #FF2424;
                font-size: 24px;
            }
        }
        .FormItem{
            margin-bottom: 22px;
        }
        .FormItemContent{
            margin-top: 12px;
        }
    }
    .BtnContent{
        display: flex;
        justify-content: space-between;
        align-items: center;
        button{
            flex: 1;
            height: 36px;
            &:first-child{
                margin-right: 10px;
            }
        }
    }
}