.Header{
    width: calc(100% - 20px);
    max-width: 1100px;
    height: 76px;
    padding: 12px 0;
    margin: 0 auto;
    > div{
        &:nth-child(1){
            margin-right: 16px;
        }
        &:nth-child(2){
            margin-right: 88px;
            @media screen and (max-width: 600px) {
                margin-right: 16px;
            }
        }
    }
}

.Content{
    position: relative;
    width: calc(100% - 20px);
    max-width: 1100px;
    box-sizing: content-box;
    margin: 0 auto;
    display: flex;
    border:1px solid;
    border-color:rgba(112, 112, 112, 0.2);
    border-radius: 10px;
    > div:not(.DragAndScale){
        position: relative;
        width: 50%;
        height: auto;
        &:first-child::before{
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            background-color: rgba(112, 112, 112, 0.2);
            position: absolute;
            right: 0;
            top: 0;            
        }
        &::after{
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
        }
        >img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: url('~@assets/images/Musk.png') repeat;
        }
        >div{
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .Tips{
            position: absolute;
            top: 8px;
            right: 8px;
            width: 52px;
            height: 20px;
            color: #ffffff;
            line-height: 20px;
            font-size: 12px;
            text-align: center;
            background: rgba(29,27,27,.5);
            border-radius: 4px;
        }
    }
}

.DragAndScale{
    position: absolute;
    left: 50%;
    bottom: 20px;
    width: auto;
    height: auto;
    transform: translate(-50%, 0);
    > button:first-child{
        margin-right: 12px;
    }
    > button:last-child{
        margin-left: 12px;
    }
    .scale_label{
        border-top: 1px solid #f4f4f4;
        border-bottom: 1px solid #f4f4f4;
        background-color: #f4f4f4;
        width: 60px;
        height: 24px;
        line-height: 24px;
        text-align: center;
    }
    :global(.ant-btn-default){
        border-color: #f4f4f4;
        &:disabled{
            background-color: #d9d9d9;
            border-color: #d9d9d9;
        }
    }
}

.CanvasWrap{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    > div{
        position: absolute!important;
        &:last-child{
            pointer-events: none;
        }
    }
    &.interactive{
        >div:last-child{
            pointer-events: all;
        }
    }
}