.FeedbackContainer{
    padding: 24px;
    .FormContent{
        label{
            position: relative;
            font-size: 14px;
            line-height: 16px;
            span{
                position: absolute;
                transform: translate(0, 6px);
                color: #FF2424;
                font-size: 24px;
            }
        }
        .FormItem{
            margin-bottom: 22px;
        }
        .FormItemContent{
            margin-top: 12px;
        }
    }
    .BtnContent{
        display: flex;
        justify-content: space-between;
        align-items: center;
        button{
            flex: 1;
            height: 36px;
            &:first-child{
                margin-right: 10px;
            }
        }
    }
}