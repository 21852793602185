.SelectScene {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px 0;
    border-radius: 10px;
    margin-top: 10px;
    background: linear-gradient(180deg, #eff2fd, #FFFFFF 50%);
    .scene_change_tab {
        width: calc(100% - 4px);
        height: 35px;
        cursor: pointer;
        display: flex;
        transition: all 0.2s;
        position: relative;
        .scene_change_tab_item {
            position: relative;
            width: 50%;
            height: 100%;
            text-align: center;
            line-height: 35px;
            z-index: 1;
            &::before, &::after{
                z-index: -1;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 31px;
                height: 35px;
                background-position: left bottom;
                background-size: 100%;
                background-repeat: no-repeat;
            }
            &.actived{
                &:first-child{
                    background: linear-gradient(270deg, transparent 20px, #FFFFFF 20px);
                    border-top-left-radius: 10px;
                    &::before{
                        background-image: url('~@assets/images/tab_bg_side.svg');
                        left: 100%;
                        transform: translate(-20px, 0);
                    }
                }
                &:last-child{
                    background: linear-gradient(90deg, transparent 20px, #FFFFFF 20px);
                    border-top-right-radius: 10px;
                    &::before{
                        background-image: url('~@assets/images/tab_bg_side.svg');
                        left: -10px;
                        transform: scaleX(-1);
                    }
                }
                &:not(:first-child):not(:last-child) {
                    background: linear-gradient(90deg, transparent 20px, #FFFFFF 20px, #FFFFFF 52%, transparent 52%), linear-gradient(270deg, transparent 20px, #FFFFFF 20px, #FFFFFF 52%, transparent 52%);
                    &::before{
                        background-image: url('~@assets/images/tab_bg_side.svg');
                        left: 100%;
                        transform: translate(-20px, 0);
                    }
                    &::after{
                        background-image: url('~@assets/images/tab_bg_side.svg');
                        left: -10px;
                        transform: scaleX(-1);
                    }
                }
            }
            &.disabled{
                cursor: not-allowed;
            }
            >div{
                width: 100%;
                height: 100%;
                &.disabledIcon:after{
                    content: "敬请期待";
                    display: block;
                    width: 64px;
                    height: 18px;
                    border-top-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                    background: rgba(254, 41, 94, 1);
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    color: #FFFFFF;
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform-origin: right top;
                    transform: scale(.7) translate(0, -50%);
                }
                &.newIcon:after{
                    content: "NEW";
                    display: block;
                    width: 47px;
                    height: 18px;
                    border-radius: 4px;
                    background: rgba(254, 41, 94, 1);
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    color: #FFFFFF;
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translate(0, -50%);
                }
                &.limitedFreeIcon::after{
                    content: "限时免费";
                    display: block;
                    width: 64px;
                    height: 18px;
                    border-top-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                    background: linear-gradient(95.16deg, #393EFD -7.54%, #8C29FE 93.45%);
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    color: #FFFFFF;
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform-origin: right top;
                    transform: scale(.7) translate(0, -50%);
                }
            }
        }
    }
    .scene_change_tab_content{
        width: calc(100% - 4px);
        height: calc(100% - 40px);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #FFFFFF;
        &.active_first{
            border-top-left-radius: 0;
        }
        &.active_last{
            border-top-right-radius: 0;
        }
    }
}