.Container{
    padding: 0 8px;
    .Title{
        margin-top: 16px;
        padding: 0 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 14px;
        span:nth-child(1){
            font-weight: 400;
            font-size: 12px;
        }
        span:nth-child(2){
            cursor: pointer;
            color: #35415C;
            font-size: 12px;
            font-weight: 400;
        }
    }
    .Content{
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 13px;
        .SelectionItemBox{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
        }
    }
    .empty_wrap{
        width: 100%;
        height: 360px;
        display: flex;
        justify-content: center;
        align-items: center;
    }    
}