/* 侧边栏任务列表 */
.Sidebar_tasks{
    position: absolute;
    left: 0;
    bottom: 260px;
    width: 100%;
    text-align: center;
    transform: translate(0, 100%);
    .money_box {
        display: flex;
        justify-content: center;
        width: 64px;
        height: 64px;
        cursor: pointer;
        &:not(:first-child){
            margin-top: 20px;
        }
        .money_box_ {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius:16px;
            width: 72px;
            padding-bottom: 2px;
            i{
                display: block;
                width: 32px;
                height: 32px;
            }
            &.energy_point i{
                background: url("~@assets/images/energy_point.png") center / cover no-repeat;
            }
            &.energy_point:hover i{
                background: url("~@assets/images/energy_point_hover.png") center / cover no-repeat;
            }
            &.icon_tasklist i{
                background: url("~@assets/images/icon_tasklist.png") center / cover no-repeat;
            }
            &.icon_tasklist:hover i{
                background: url("~@assets/images/icon_tasklist_hover.png") center / cover no-repeat;
            }
            p {
                color: #35415C;
                font-size: 12px;
                line-height: 18px;
            }
        }
    }
}