.DragDeskWrap{
    position: relative;
    height: 100%;
}

.DragDesk{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.DragTips{
    position: absolute;
    bottom: -10px;
    width: 100%;
    text-align: center;
    color:var(--color-primary);
    font-size: 12px;
    transform: translate(0, 100%);
}

.ToolBox_float{
    position: absolute;
    top: -10px;
    right: 0;
    transform: translate(0, -100%);
}
