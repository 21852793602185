.Container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    >p{
        margin-bottom: 66px;
        white-space: nowrap;
        color: #1d1b1b;
        font-size: 16px;
        line-height: normal;
    }
    >div.mainPart{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 634px;
    }
    >div.rightPart{
        margin-left: 36px;
    }
    &.Tiny >div.rightPart{
        display: none;
    }
}
.defaultPageUploader{
    &:global(.ant-upload-wrapper.ant-upload-picture-card-wrapper){
        width: 100%;
        :global(.ant-upload.ant-upload-select),
        :global(.ant-upload.ant-upload-drag){
            box-sizing: content-box;
            width: 100%;
            height: auto;
            background-color: transparent;
            margin: 0;
            margin-inline-end: 0;
            border-radius: 12px;
            &:hover{
                background-color: var(--color-98);
            }
        }
    }
}
.defaultPageLeft{
    position: relative;
    width: 100%;
    height: 353px;
    // &::after{
    //     content: "";
    //     display: block;
    //     width: 100%;
    //     height: 0;
    //     padding-bottom: 55.68%;
    // }
    >div{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        button{
            position: relative;
            margin-bottom: 16px;
            width: 228px;
            height: 44px;
            font-size: 20px;
            overflow: hidden;
            border: none;
        }
        .BatchIcon{
            position: absolute;
            top: 0;
            right: 0;
            width: 44px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFFFFF;
            background: linear-gradient(112.73deg, #BCAEFF -2.58%, #8B8FFF 98.29%);
            border-bottom-left-radius: 20px;
            span{
                display: block;
                font-size: 12px;
                transform: scale3d(.9, .9, 1);
            }
        }
        .UploadTips{
            margin-bottom: 12px;
            font-weight: 300;
            color: #979797;
            width: 100%;
            font-size: 13px;
            max-width: 80%;
        }        
        ul{
            margin-bottom: 40px;
            display: flex;
            justify-content: center;
            li{
                .exampleGroup, .exampleSingle{
                    cursor: pointer;
                    display: flex;
                    height: 70px;
                    border-radius: 10px;
                    overflow: hidden;
                    img{
                        width: auto;
                        height: 100%;
                    }
                }
                &:not(:last-child){
                    margin-right: 10px;
                }
            }
        }
    }
}
.PickFromProject{
    margin-top: 56px;
    max-width: 328px;
    width: 100%;
    height: 46px;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    color: var(--color-primary)!important;
    background-color: var(--color-95)!important;
    border: 1px solid var(--color-85)!important;
}