.VersionList{
    margin: 20px 0;
    padding: 0 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    >div{
        position: relative;
        cursor: pointer;
        flex: 1;
        height: 36px;
        background: #EFEFEF;
        border-radius: 10px;
        padding: 0 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    :global(.ant-segmented-item), :global(.ant-segmented-thumb){
        border-radius: 8px;
    }
    :global(.ant-segmented-item:hover:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled)){
        background-color: transparent!important;
    }
    :global(.ant-segmented-item-label){
        padding: 0;
    }
}

.SegmentedContent{
    position: relative;
    font-size: 12px;
    &.new::after{
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgba(254, 41, 94, 1);
        position: absolute;
        top: 0;
        right: 0;
    }
}
