.ProjectTask{
    width: 100%;
    transition: margin 250ms;
    @media screen and (max-width: 1200px) {
        &:not(:last-child){
            margin-top: .2rem;
        }
    }
    &_top{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        &_content{
            display: flex;
            align-items: flex-end;
            &_left{
                min-width: 320px;
                height: 24px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color:#1d1b1b;
                font-size: 16px;
            }
            .title{
                display: inline-block;
                font-weight: 600;
                &:not(:last-child):after{
                    content: "-";
                    margin: 0 5px;
                    display: inline-block;
                    height: 24px;
                }
            }
            button{
                // min-width: 70px;
                margin-left: 20px;
                font-size: 12px;
                height: 28px;
                line-height: 27px;
                padding: 0 20px;
                border-radius: 8px;
                &:not(:last-child){
                    margin-left: 50px;
                }
            }
        }
        &_content.bookmark{
            font-weight:700;
            color:#1d1b1b;
            font-size:16px;
            line-height: 28px;
            .bookmark_icon{
                display: inline-block;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background: url('~@assets/images/bookmark_checked.svg'), #e8e9ee center / cover no-repeat;
                margin-right: 16px;
            }
        }
    }
    &_content{
        display: flex;
        &_head{
            flex: none;
            width: 120px;
            display: flex;
            flex-flow: row-reverse;
            margin-right: 16px;
            img{
                width: 42px;
                height: 42px;
                object-fit: cover;
                border-radius: 8px;
                background-color: #000;
            }
        }
        &_body{
            flex: 1;
            display: flex;
            flex-wrap: wrap; 
            padding-left: 1.4rem;
            padding-right: .4rem;
            transition: padding 250ms;
            @media screen and (max-width: 1200px) {
                padding: 0;
            }
        }
        &_result{
            position: relative;
            width: calc((100% - 2.4rem) / 4);
            margin-bottom: .5rem;
            transition: width 250ms, margin 250ms;
            @media screen and (max-width: 1200px) {
                width: calc((100% - .6rem) / 4);
                margin-bottom: .2rem;
            }
            &:not(:nth-child(4n)){
                margin-right: .8rem;
                @media screen and (max-width: 1200px) {
                    margin-right: .2rem;
                }
            }
        }
    }
}

.reelect_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 6px;
    height: 568px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: .05rem;
    }
    &::-webkit-scrollbar-track {
        background-color: #e8e9ee;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #FFF;
        border-radius: 10px;
    }
    video {
        flex: none;
        width: calc((100% - 24px) / 5);
        border-radius: 12px;
        border: 2px solid #fff;
        cursor: pointer;
        margin-bottom: 6px;
        &:not(:nth-child(5n)){
            margin-right: 6px;
        }
    }
    .video_select {
        border: 2px solid var(--color-primary);
    }
}