.Container{

}

.Title{
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}

.Controller{
    margin-bottom: 10px;
}

.Content{
    position: relative;
    width: calc(100% - 20px);
    max-width: 1100px;
    box-sizing: content-box;
    margin: 0 auto;
    display: flex;
    border:1px solid;
    border-color:rgba(112, 112, 112, 0.2);
    border-radius: 10px;
    > div:not(.DragAndScale){
        position: relative;
        width: 100%;
        height: auto;
        &:first-child::before{
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            background-color: rgba(112, 112, 112, 0.2);
            position: absolute;
            right: 0;
            top: 0;            
        }
        &::after{
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
        }
        >img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: url('~@assets/images/Musk.png') repeat;
        }
        >div{
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .Tips{
            position: absolute;
            top: 8px;
            right: 8px;
            width: 52px;
            height: 20px;
            color: #ffffff;
            line-height: 20px;
            font-size: 12px;
            text-align: center;
            background: rgba(29,27,27,.5);
            border-radius: 4px;
        }
    }
}