.ToolBox{
    white-space: nowrap;
    button{
        width: 86px;
        height: 28px;
        font-size: 12px;
        line-height: 28px;
        border-radius: 18px;
        box-shadow: none;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        &:not(:last-child){
            margin-right: 10px;
        }
        &:not(.border){
            border: none;
        }
    }
    .Upload {
        display: inline-flex;
        margin-right: 10px;
        &:last-child{
            float: right;
            margin-right: 0;
        }
    }
}