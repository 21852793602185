.task-list{
    position: relative;
    overflow: hidden;
    max-height: 600px;
    border: none;
    padding: 0;
    margin: 0;
    .task-list-header{
        position: sticky;
        top: 0;
        border-top: 1px solid #e8e9ee;
        background: #FFFFFF;
        display: flex;
        height: 45px;
        color:#1d1b1b;
        font-size:16px;
        height: 46px;
        line-height: 46px;
        padding-left: 80px;
        padding-right: 20px;
        span{
            display: inline-block;
            width: 20%;
        }
    }
    .task-list-body{
        background:#e8e9ee;
        padding: 8px 20px 8px 20px;
        height: 500px;
        overflow-x: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: .05rem;
        }
        &::-webkit-scrollbar-track {
            background-color: #e8e9ee;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FFF;
            border-radius: 10px;
        }
    }
}