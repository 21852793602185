@import '../../comom.scss';
.Sidebar {
    flex: none;
    position: relative;
    height: 100%;
    min-height: 580px;
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    .Sidebar_item {
        width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        cursor: pointer;
        position: relative;
        border-radius: 12px;
        padding: 5px 0;
        margin-bottom: 5px;
        .sidebar_icon {
            width: 32px;
            height: 32px;
        }
        p {
            width: 100%;
            color:#5f7098;
            font-size: 12px;
            text-align: center;
        }
        &.actived {
            background-color: #fff;
            box-shadow: 0 4px 4px 0 #EBF0FF;
            p {
                color: var(--color-primary);
                font-weight: bold;
            }
        }
    }    
}

.CustomPopover{
    .ant-popover-inner{
        padding: 0;
        background: transparent;
        box-shadow: none;
    }
}
.PopoverContent{
    width: 176.43px;
    height: 88px;
    background: url('~@assets/images/Member_Specific_Popover.svg') center / cover no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >span{
        color:#ffffff;
        font-size:13px;
        line-height: 1;
    }
    button{
        border: none;
        width: 81px;
        height: 22px;
        color:var(--color-primary);
        font-size: 12px;
        line-height: 22px;
        padding: 0;
        margin-top: 13px;
        border-radius:8px;
        box-shadow:0px 3px 6px rgba(0, 0, 0, 0.16);
        z-index: 1;
        &::before{
            content: "";
            display: block;
            width: 18.35px;
            height: 15.73px;
            background: url('~@assets/images/VIP_Crown.svg') center / cover no-repeat;
            position: absolute;
            top: 0;
            right: 0;
            transform: rotate(0deg) translate3d(37%, -52%, 0);
            z-index: 0;
        }
    }
}