.editLeftBar {
    width: 320px; 
    padding: 15px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: .05rem;
    }
    &::-webkit-scrollbar-track {
        background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #e8e9ee;
        border-radius: 10px;
    }
    .rotate_sty {
        transform: rotate(180deg);
        transition: transform 0.2s ease-in-out;
    }

    .title {
        display: flex;
        align-items: center;
        i {
            display: inline-block;
            margin-right: 5px;
            width: 22px;
            height: 22px;
        }
    }

    :global(.ant-collapse-item:hover) {
        background-color: #EFF2FD!important;
        border-radius: 8px!important;
    }

    :global(.ant-collapse-item-active) {
        :global(.ant-collapse-header) {
            background-color: #EFF2FD;
            border-radius: 8px 8px 0px 0px!important;
            font-weight: bold;
        }
        :global(.ant-collapse-content){
            border: 1px solid rgba(239, 242, 253, 1);
            border-top: 0;
            border-radius: 0px 0px 8px 8px!important;
            overflow: hidden;
        }
        :global(.ant-collapse-content-box) {
            background-color: #F8F9FF;
            // border-radius: 0px 0px 8px 8px!important;
            // border: 1px solid rgba(239, 242, 253, 1)!important;
        }
        .title0 > i {background: url('~@assets/images/Menu bar_Keying_normat (2).svg') center / cover no-repeat;}
        .title1 > i {background: url('~@assets/images/Menu bar_Keying_normat.svg') center / cover no-repeat;}
        .title2 > i {background: url('~@assets/images/Menu bar_Deformation_Selected.svg') center / cover no-repeat;}
        .title3 > i {background: url('~@assets/images/Menu bar_Erase_normat.svg') center / cover no-repeat;}
        .title4 > i {background: url('~@assets/images/Menu bar_HD_normat.svg') center / cover no-repeat;}
        .title5 > i {background: url('~@assets/images/Frame 965.svg') center / cover no-repeat;}

    }
    .title0 > i {background: url('~@assets/images/Menu bar_Keying_normat (1).svg ') center / cover no-repeat;}
    .title1 > i {background: url('~@assets/images/icon_light.svg') center / cover no-repeat;}
    .title2 > i {background: url('~@assets/images/icon_beautify.svg') center / cover no-repeat;}
    .title3 > i {background: url('~@assets/images/icon_magicErase.svg') center / cover no-repeat;}
    .title4 > i {background: url('~@assets/images/icon_imageUpgrade.svg') center / cover no-repeat;}
    .title5 > i {background: url('~@assets/images/Frame 964.svg') center / cover no-repeat;}
   .mattingBox {
    i {
        display: inline-block;
        cursor: pointer;
        width: 56px;
        height: 56px;
        margin-top: 30px;
        background: url('~@assets/images/icon_matting_btn.svg') center / cover no-repeat;
    }
   }
}