.Container {
    padding: 0 8px;
    .Content {
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 13px;
        .SelectionItemBox {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            .sceneItem {
                width: calc((100% - 30px) / 3);  
                &::after{
                    content: "";
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                }
                &:not(:nth-child(3n)) {
                    margin-right: 15px;
                }
                &:not(:nth-child(-n+3)){
                    margin-top: 12.5px;
                }
                border: 2px solid #fff;
                background-color: #e8e9ee;
                border-radius: 12px;
                position: relative;
                overflow: hidden;
                > img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
    }
}
