.Container{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 50px 0 10px;
}

.MaskDeskWrap{
    position: relative;
    height: 100%;
}
.MaskDesk{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    >img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .BaseMap{
        opacity: 1;
    }
    .MosaicMap{
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: .2;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAADBJREFUOE9jfPP69X8GPEBYRASfNAPjqAHDIgz+AwG+iH7z5s1ZfPKMowYwDIMwAACuR1xZvSaHFwAAAABJRU5ErkJggg==') repeat;
    }
    .MaskMap{
        // 1E90FFCC
        // filter: invert(52%) sepia(98%) saturate(7303%) hue-rotate(237deg) brightness(96%) contrast(107%);
        opacity: .8;
    }
}

.ToolBox{
    position: absolute;
    width: 100%;
    top: -10px;
    right: 0;
    transform: translate(0, -100%);
    &.autoWidth{
        width: auto;
    }
}

.Loading{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .6);
}

.UploadWrap{
    display: inline-block;
    width: 100%;
    height: 100%;
    :global(.ant-upload.ant-upload-drag){
        display: inline-block;
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        text-align: left;
    }
    :global(.ant-upload.ant-upload-btn){
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 0;
    }
    :global(.ant-upload-drag .ant-upload-drag-container){
        display: inline-block;
        width: 100%;
        height: 100%;
    }
    :global(.ant-upload-drag .ant-upload){
        padding: 0;
    }
}