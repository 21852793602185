.Container{
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 44px;
    .TabHanlde{
        position: absolute;
        width: 100%;
        top: 0;
        overflow: hidden;
        white-space: nowrap;
        .Tab_bar{
            position: relative;
            width: 50%;
            max-width: 247px;
            height: 44px;
            cursor: pointer;
            display: inline-block;
            text-align: center;
            font-size: 20px;
            line-height: 46px;
            color: #000000;
            background-color: transparent;
            z-index: 1;
            // &:nth-child(1)::before {
            //     display: none;
            // }
            &::before{
                z-index: -1;
                content: "";
                position: absolute;
                left: 0;
                width: 114.98%;
                height: 100%;
                background-position: left bottom;
                background-size: 100%;
                background-repeat: no-repeat;
            }
            &:nth-child(2)::before{
                left: 50%;
                transform: translate(-50%, 0);
            }
            &.actived{
                &:first-child::before{
                    background-image: url('~@assets/images/tag_bg_left.svg');
                }
                &:nth-child(2)::before{
                    background-image: url('~@assets/images/tag_bg_middle.svg');
                }
            }
            
        }
    }
    .TabContent{
        background-color: #FFFFFF;
        border-radius: 12px;
        border-top-left-radius: 0;
        flex: 1;
        overflow: hidden;
        &.activedNotFirst{
            border-top-left-radius: 12px;
        }
        .TabContentItem{
            width: 100%;
            height: 100%;
        }
        &.hasRightComp{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    &:hover{
        .Tab_bar{
            border-bottom-color: var(--color-primary);
        }
        .Tab_bar.actived{
            border-color: var(--color-primary);
            border-bottom-color: transparent;
        }
        .TabContent{
            border-color: var(--color-primary);
        }
    }
}

