.Container{
    width: calc((100% - 30px) / 3);
    &:not(:nth-child(3n)) {
        margin-right: 15px;
    }
    &:not(:nth-child(-n+3)){
        margin-top: 12.5px;
    }
    &.col_4{
        width: calc((100% - 45px) / 4);
        &:not(:nth-child(3n)) {
            margin-right: 0;
        }
        &:not(:nth-child(4n)) {
            margin-right: 15px;
        }
        &:not(:nth-child(-n+3)){
            margin-top: 0;
        }
        &:not(:nth-child(-n+4)){
            margin-top: 12.5px;
        }
    }
    &::after{
        content: "";
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }
    &.Pose::after{
        padding-bottom: 166.86%;
    }
    border: 2px solid #fff;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    .itemImg {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }
    &.selected {
        border: 2px solid var(--color-primary);
        overflow: hidden;
    }
    .itemTitle{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px;
        background-color: rgba(0, 0, 0, 0.3);
        color: #fff;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
    }
    &:hover{
        .del_btn{
            visibility: visible;
        }
    }
}
.multiple_selected{
    background: var(--color-primary);
    color: #FFFFFF;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 14px;
    border-bottom-left-radius: 12px;
    >img{
        display: block;
        width: 11px;
        height: 8px;
    }
}
.private_icon{
    display: block;
    width: 26px;
    height: 12px;
    background: url('~@assets/images/icon_private.svg') left top / cover no-repeat;
    position: absolute;
    left: 0;
    top: 0;
}
.new_icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 16px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    background: linear-gradient(104.07deg, rgba(104, 107, 253, 0.7) 19.55%, rgba(211, 197, 255, 0.7) 89.24%);
    border-bottom-right-radius: 12px;
    span{
        display: inline-block;
        transform: scale3d(.7, .7, 1);
    }
}

.more_scene_handler{
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: 30.5%;
    border-bottom-left-radius: .08rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    .more_scene_handler_img{
        display: block;
        position: absolute;
        width: 63.33%!important;
        height: 63.33%!important;
        background: url('~@assets/images/icon_gallery2.svg') center / cover no-repeat;
    }
    &::after{
        content: "";
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }
}

.del_btn{
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    display: block;
    width: 20px;
    height: 20px;
    z-index: 10;
    visibility: hidden;
    background: url('~@assets/images/Remove_Normal.svg') center / cover no-repeat;
    transition: background-image .2s;
    &:hover{
        background-image: url('~@assets/images/Remove_hover.svg')
    }
    &:active{
        background-image: url('~@assets/images/Remove_Press.svg')
    }
}

