// ant-btn
.ant-btn{
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 8px;
    &.ant-btn-default{
        border-color: rgba(239, 242, 253, 1);
    }
    &.ant-btn-default:disabled{
        background-color: #ffffff;
    }
    &.ant-btn-default:not(:disabled){
        background: hsl(0, 0%, 100%);
        color: #1d1b1b;
        &:hover{
            background: hsl(0, 0%, 98%);
        }
    }
    &.ant-btn-primary:not(:disabled){
        background: linear-gradient(165.04deg,var(--color-primary-lighter) 0%,var(--color-primary) 100%);
        color: hsl(0, 0%, 100%);
        &:hover{
            background: linear-gradient(165.04deg,var(--color-primary-lighter) 0%,var(--color-primary-lighter) 100%);
        }
    }
    &.ant-btn-primary:disabled, &.ant-btn-primary.ant-btn-disabled{
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.25);
        background: rgba(0, 0, 0, 0.04);
        border-color: rgba(0, 0, 0, 0.04);
    }
}

// ant-tour
.ant-tour {
    width: 275px;
    >.ant-tour-content{
        >.ant-tour-inner{
            .ant-tour-close{
                padding: 2px 8px;
                width: auto;
                height: auto;
                border-radius: 90px;
                top: 0;
                right: 0;
                transform: translate(0, -32px);
                color: #F5F6FC;
                background: rgba(240, 240, 240, 0.1);
                font-size: 12px;
                line-height: 16px;
            }
            .ant-tour-footer{
                .ant-tour-buttons{
                    display: none;
                }
            }
        }
    }
}