.Empty{
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
}

.ModalFooter{
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    .CouponInput{
        width: 364px;
        height: 28px;
        background: #eff2fd;
        border-radius: 8px;
        padding: 2px 2px 2px 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        input{
            flex: 1;
            padding: 0;
            border-radius: 0;
            margin-right: 10px;
            font-size: 12px;
            line-height: 12px;
        }
        button{
            flex: none;
            border: none;
            padding: 0;
            width: 109px;
            height: 24px;
            background: linear-gradient(146.37deg,#ff8449 0%,#ff5763 100%);
            border-radius: 6px;
            color: #ffffff;
            font-size: 16px;
            line-height: 24px;
            :global(.ant-wave){
                color: #ff8449;
            }
            &:hover{
                background: linear-gradient(146.37deg,#ff8449 0%,#ff5763 100%)!important;
                color: #ffffff!important;
            }
        }
    }
}

.CouponWrap{
    height: 382px;
    padding: 20px 12px;
    background-color: #f4f4f4;
}
.CouponContent{
    height: 100%;
    .CouponCard{
        overflow: hidden;
        position: relative;
        cursor: pointer;
        width: 100%;
        max-width: 364px;
        height: 106px;
        margin: 0 auto;
        background: #e8e9ee;
        border-radius: 16px;
        &.actived{
            background:#ffa87f;
        }
        &:not(:last-child){
            margin-bottom: 12px;
        }
        .CardContent{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &.disabled{
                opacity: .8;
                filter: grayscale(100%);
            }
        }
        .DisabledIcon{
            display: block;
            width: 70px;
            height: 70px;
            position: absolute;
            right: 16%;
            top: 50%;
            transform: translate(50%, -50%);
            background: center / cover no-repeat;
            &.expired{
                background-image: url('~@assets/images/icon_expired.svg');
            }
            &.used{
                background-image: url('~@assets/images/icon_used.svg');
            }
        }
        .CouponContent{
            position: relative;
            width: 350px;
            height: 94px;
            background: url('~@assets/images/coupon_card.svg') center / cover no-repeat;
            border-radius: 13px;
            display: flex;
            .LeftBox{
                width: 42%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    font-weight:900;
                    color: #ff834a;
                    line-height: 1;
                    .Big{
                        font-size: 56px;
                    }
                    .Middle{
                        font-size: 40px;
                    }
                    .Unit{
                        position: absolute;
                        right: -4px;
                        bottom: 5px;
                        transform: translate(100%, 0);
                        font-weight: 500;
                        color: #ff834a;
                        font-size: 16px;
                    }
                }
            }
            .RightBox{
                position: relative;
                width: 58%;
                height: 100%;
                button{
                    position: absolute;
                    left: 50%;
                    top: 38px;
                    transform: translate(-50%, 0);
                    padding: 0;
                    width: 111px;
                    height: 28px;
                    border: 1px solid;
                    border-color: #ffa87f;
                    border-radius: 19px;
                    color: #ff8449;
                    font-size: 12px;
                    line-height: 28px;
                    &:global(.ant-btn-default:disabled){
                        cursor: pointer;
                        background-color: transparent;
                    }
                    :global(.ant-wave){
                        color: #ff8449;
                    }
                    &.actived{
                        color: #ffffff;
                        background: #ff8449;
                    }
                }
                .name{
                    position: absolute;
                    left: 50%;
                    top: 13px;
                    transform: translate(-50%, 0);
                    width: 100%;
                    text-align: center;
                    color: #ff8449;
                    font-size: 12px;
                    font-weight: 700;
                }
                .expiredTime{
                    position: absolute;
                    left: 50%;
                    top: 70px;
                    transform: translate(-50%, 0);
                    width: 100%;
                    text-align: center;
                    color: #ff8449;
                    font-size: 12px;
                }
            }
        }
        .CouponRibbon{
            position: absolute;
            left: 18px;
            top: 18px;
            transform-origin: center top;
            transform: translate(-50%, 0) scale(0.83) rotate(-45deg);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 24px;
            background: linear-gradient(to right, #ff8449, #ff5763);
            color: #FFFFFF;
            font-size: 12px;
            line-height: 1;
        }
    }
}