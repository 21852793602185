.Container{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .ResourceBox{
        height: calc(100% - 90px);
        width: 100%;
        padding: 0 8px;
        overflow: hidden;
    }
    .GenerateBox{
        width: calc(100% - 32px);
        position: absolute;
        bottom: 8px;
        .use_point{
            display: flex;
            justify-content: flex-end;
            align-items: baseline;
            margin-bottom: 12px;
            img {
                margin-left: 12px;
                height: 10px;
                width: 10px;
                margin-right: 5px;
            }
        }
        >button{
            width: 100%;
            height: 44px;
            font-size: 20px;
        }
    }
}
