.ModelFilterSelect{
    :global(.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder) {
        color: #6d6d6d;
    }
    :global(.ant-col) > label{
        display: inline-block;
        height: 24px;
        line-height: 24px;
        margin-right: 10px;
        &:not(:first-child){
            margin-left: 20px;
        }
    }
    :global(.ant-select-multiple.ant-select-sm){
        font-size: 12px;
        :global(.ant-select-selection-overflow){
            flex-wrap: nowrap;
        }
        :global(.ant-select-selection-item){
            margin-inline-end: 2px;
        }
    }
    :global(.ant-select-selection-overflow-item){
        &:not(:first-child){
            margin-left: 2px;
        }
    }
    .ModelFilterSelect_tag{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0 4px;
        font-size: 12px;
        height: 100%;
        background-color: #F0F0F0;
    }
}