.previewImageRoot{
    z-index: 1001;
    .ant-image-preview-mask{
        background-color: rgba(0, 0, 0, 0.8);
    }
    .ant-image-preview-switch-left{ // 隐藏原始的上一个切换
        display: none;
    }
    .ant-image-preview-switch-right{ // 隐藏原始的下一个切换
        display: none;
    }
    .ant-image-preview-close{ // 隐藏原始的关闭按钮
        display: none;
    }
    .ant-image-preview-img{ // 使可缩放原始大图顶天立地
        max-height: 100%;
    }
    &.original .ant-image-preview-img-wrapper{
        align-items: center;
        margin-top: 0;
    }
    .ant-image-preview-img-wrapper{
        align-items: flex-start;
        margin-top: 80px;
    }
    .image-preview-custom-wrapper{
        position: relative;
        width: calc(100% - 20px);
        max-width: 979px;
        height: calc(100% - 80px - 50px);
        display: flex;
        justify-content: center;
        align-items: center;
        .image-preview-custom-body{
            pointer-events: all;
            position: relative;
            display: flex;
            height: 100%;
            .image-preview-custom-imgwrap{
                user-select: none;
                // min-width: 508px;
                height: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(10,10,10,.8);
                border-radius: 16px 0 0 16px;
                overflow: hidden;
                >.ant-image, >.ant-image .ant-image-placeholder .ant-image{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .image-preview-custom-satisfactionwrap{
                width: 254px;
                height: 100%;
            }
            .image-preview-custom-close{
                cursor: pointer;
                position: absolute;
                top: 0;
                right: -8px;
                transform: translate(100%, -50%);
                width: 56px;
                height: 56px;
                border-radius: 4px;
                background: url("~@assets/images/icon_close.png") center / cover no-repeat;
                &:hover{
                    background-color: rgba(255, 255, 255, 0.06);
                }
                @media screen and (max-width: 1024px) {
                    top: 0px;
                    right: 0;
                    transform: translate(0, -100%);
                }
            }
            .image-preview-custom-pre{
                cursor: pointer;
                position: absolute;
                top: 50%;
                left: -20px;
                transform: translate(-100%, -50%);
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background: url('~@assets/images/icon_left_arrow.svg') center / cover no-repeat;
                &:hover{
                    background-color: rgba(255, 255, 255, 0.06);
                }
                @media screen and (max-width: 1024px) {
                    left: 10px;
                    transform: translate(0, -50%);
                }
            }
            .image-preview-custom-next{
                cursor: pointer;
                position: absolute;
                top: 50%;
                right: -20px;
                transform: translate(100%, -50%) scaleX(-1);
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background: url('~@assets/images/icon_left_arrow.svg') center / cover no-repeat;
                &:hover{
                    background-color: rgba(255, 255, 255, 0.06);
                }
                @media screen and (max-width: 1024px) {
                    right: calc(254px + 10px);
                    transform: translate(0, -50%) scaleX(-1);
                }
            }
        }
        .image-preview-custom-img{
            cursor: zoom-in;
            display: block;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
        .image-preview-custom-img_hdribbon{
            position: absolute;
            text-align: center;
            left: 14px;
            top: 14px;
            transform: translate(-50%,-50%) rotate(-45deg);
            width: 200px;
            height: 40px;
            line-height: 30px;
            background-color: var(--color-primary);
            color: #FFFFFF;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
        .image-preview-custom-tools{
            position: absolute;
            bottom: 20px;
            left: 0;
            width: 100%;
            z-index: 1;
        }
        .image-preview-custom-loading{
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(29, 27, 27, .8);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            color: #FFFFFF;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span{
                margin-top: 16px;
                font-size: 12px;
            }
        }
        .image-preview-custom-segmented{
            width: fit-content;
            position: absolute;
            top: -12px;
            left: 50%;
            transform: translate(-50%, -100%);
            right: 0;
            pointer-events: all;
            .ant-segmented{
                border-radius: 16px;
                padding: 4px;
                background-color: #ECEBEB;
                .ant-segmented-group{
                    border-radius: 16px;
                    overflow: hidden;
                    background-color: #FFFFFF;
                }
                .ant-segmented-item-label{
                    width: 80px;
                    min-height: 22px;
                    font-size: 12px;
                    line-height: 22px;
                }
            }
            .ant-segmented-thumb{
                background-color: var(--color-primary);
                border-radius: 0;
            }
            .ant-segmented-item{
                border-radius: 0;
            }
            .ant-segmented-item:first-child{
                border-top-left-radius: 16px;
                border-bottom-left-radius: 16px;
            }
            .ant-segmented-item:last-child{
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px;
            }
            .ant-segmented-item:hover:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled){
                background-color: transparent!important;
            }
            .ant-segmented-item-selected{
                background-color: var(--color-primary);
                color: #FFFFFF;
            }
        }
    }
    .ant-image-preview-footer{
        bottom: 24px;
        height: 90px;
        .ant-image-preview-progress{ // 隐藏原始的切换
            display: none;
        }
        .image-preview-custom-goback{
            cursor: pointer;
            position: fixed;
            right: 1.74rem;
            top: 64px;
            font-size: 16px;
            background: #000000;
            width: 194px;
            height: 48px;
            border-radius: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            &::before{
                content: "";
                display: block;
                width: 24px;
                height: 24px;
                margin-right: 10px;
                background: url("~@assets/images/icon_rollback.svg") center / cover no-repeat;
            }
            &:hover{
                background: #707070;
            }
        }
        .image-preview-custom-carousel-wrap{
            position: relative;
            overflow: hidden;
            width: 700px;
            height: 100%;
            .image-preview-custom-carousel{
                position: relative;
                width: max-content;
                min-width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: left .2s;
                padding: 0 10px;
            }
            .preview-thumbnails-img, video{
                overflow: hidden;
                flex: none;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 72px;
                height: 72px;
                object-fit: contain;
                background-color: rgba(255, 255, 255, 0.4);
                border: 2px solid;
                border-color: transparent;
                border-radius:8px;
                transition: all .2s;
                cursor: pointer;
                &:not(:last-child){
                    margin-right: 20px;
                }
                >img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .preview-thumbnails-img.actived, video.actived{
                border-color:var(--color-primary);
                transform: scale(1.25);
            }
        }
    }
}