
.mainBox {
    display: flex;
    position: relative;
    height: 100%;
}
.modelLeftIndex {
    position: relative;
    flex: none;
    background-color: #fff;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    transition: all .2s;
    .modelLeftbox {
        height: calc(100% - 90px);
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 0 8px;
        transition: all .2s;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #e8e9ee;
            border-radius: 5px;
        }
    }
    &.modelLeftIndex_radius {
        border-radius: 12px 0 0 12px;
    }
}

.modelDrawerbox {
    position: absolute;
    background-color: #fff;
    height: 100%;
    top: 0;
    left: 360px;
    z-index: 999;
    width: 0;
    overflow: hidden;
    transition: all .25s ease;
    &.drawer_open {
        width: 522px;
        border-radius: 0 12px 12px 0;
    }
}

.left_mask{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, .6);
    cursor: not-allowed;
    z-index: 21; // 需要在select-modal-handler之上
}

.create_btn {
    position: relative;
    width: calc(100% - 30px);
    height: 40px;
    margin: 0 15px;
    border-radius: 12px;
    box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.16);
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
}
.create_styl {
    position: relative;
    height: 18px;
    margin: 10px 15px;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .use_point {
        display: flex;
        align-items: anchor-center;
        img {
            margin-left: 10px;
            height: 14px;
            width: 14px;
        }
    }
    .num_btn {
        position: relative;
        display: inline-block;
        background-color: #EFF2FD;
        user-select: none;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        border-radius: 2px;
        cursor: pointer;
        margin: 0 5px;
        opacity: 1;
        &:hover {
            opacity: 0.8;
        }
        &.disabled{
            background-color: #F0F0F0;
            color: #CBCBCB;
        }
    }
    &>span:nth-child(6) {
        position: absolute;
        right: 0;
        font-size: 12px;
        line-height: 18px;
        span {
            margin-left: 5px;
            color: #000000;
        }
    }
}

.Title{
    margin-top: 16px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 14px;
    span.unnecessary::after{
        content: "（非必选）";
        font-size: 12px;
        color: rgba(94, 87, 87, 0.7);
    }
    span:nth-child(2){
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 18px;
        color: #35415C;
        font-size: 12px;
        line-height: 1;
        font-weight: 400;
        padding: 0 8px 0 12px;
        border-radius: 8px;
        transition: background .5s;
        &:hover{
            background: #EFF2FD;
            color: #2237F4;
        }
    }
}