.ListContainer{
    position: relative;
    height: 450px;
    padding: 16px 24px 0;
    .Title{
        font-size: 16px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 16px;
    }
    .ListContent, .ListContent2{
        position: absolute;
        width: calc(100% - 48px);
        height: calc(100% - 16px - 30px);
        visibility: visible;
        z-index: 1;
    }
    .Hidden{
        visibility: hidden;
        z-index: -1;
    }
    .EmptyContent{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.TaskItem{
    position: relative;
    display: inline-block;
    width: calc((100% - 50px) / 6);
    border-radius: 12px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    cursor: pointer;
    border: 1px solid transparent;
    &.actived {
        border-color: var(--color-primary);
    }
    &::after{
        content: "";
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }
    &:not(:nth-child(6n)) {
        margin-right: 10px;
    }
    >img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.ProjectItem{
    display: inline-block;
    width: calc((100% - 50px) / 6);
    margin-bottom: 20px;
    vertical-align: bottom;
    &:not(:nth-child(6n)) {
        margin-right: 10px;
    }
    .ProjectItemCover{
        position: relative;
        width: 100%;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 12px;
        &::after{
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
        }
        > img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 0;
        }
        &.favoriteBox{
            border: 1px solid #EDF0FF;
            background-color: transparent;
            &:hover{
                background-color: #EDF0FF;
            }
        }
        .empty_img{
            position: absolute;
            width: 37%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            &::after{
                content: "";
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: 121.8%;
            }
            background: url('~@assets/images/favorite_empty.svg') center / cover no-repeat;
        }
        .mask{
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.1);
            visibility: hidden;
            .check_view  {
                width: 100px;
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translate(-50%, 0);
                height: 25px;
                line-height: 25px;
                border-radius: .10rem;
                text-align: center;
                background: #fff;
            }
        }
        &:hover .mask {
            visibility: visible;
        }
    }
    .ProjectName{
        height: 21px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;
        .favor_icon{
            display: block;
            width: 21px;
            height: 21px;
            background: url('~@assets/images/bookmark_checked.svg') center / cover no-repeat;
        }
    }
    .UpdatedTime{
        height: 18px;
        font-size: 12px;
        line-height: 18px;
        color: #979797;
    }
}