.setPwd-box{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    &:nth-child(1){
        margin-bottom: 20px;
    }
    .setPwd_label{
        flex: none;
        margin-right: 20px;
        height: 40px;
        line-height: 40px;
    }
    .setPwd_input{
        width: calc(100% - 76px);
    }
    .err_text {
        width: 100%;
        margin-top: 10px;
        color: #d51616;
        margin-left: 76px;
    }
}

.updatePwd-box{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    &:nth-child(1), &:nth-child(2){
        margin-bottom: 20px;
    }
    .updatePwd_label{
        flex: none;
        margin-right: 20px;
        height: 40px;
        line-height: 40px;
        width: 84px;
    }
    .updatePwd_input{
        width: calc(100% - 104px);
    }
    .err_text {
        width: 100%;
        margin-top: 10px;
        color: #d51616;
        margin-left: 104px;
    }
}

.passwordModal_body{
    padding: 30px 0 0;
}