.Container{
    width: 100%;
    .Head{
        font-size: 24px;
        line-height: 40px;
        height: 40px;
        font-weight: 500;
        text-align: center;
        margin: 24px 0 16px;
    }
    .Content{
        width: 460px;
        margin: 0 auto 24px;
    }
}

.Channel{
    text-align: center;
    font-size: 20px;
    height: 32px;
    margin-bottom: 20px;
    span{
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
    }
    img{
        width: 24px;
        object-fit: cover;
        margin-right: 11px;
    }
}
.Qrcode{
    width: 200px;
    height: 200px;
    margin: 0 auto;
}

.PayPrice{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    font-weight: bold;
    color: #1d1b1b;
    font-size: 18px;
    margin: 24px 0 24px;
    flex-wrap: nowrap;
    span{ line-height: 1; }
    > span:nth-child(1){
        width: auto;
        font-weight: normal;
        color:#1d1b1b;
        font-size: 16px;
        text-align:center;
        margin-bottom: 0;
    }
    > span:nth-child(2){
        font-size: 18px;
    }
    > span:nth-child(3){
        font-size: 32px;
        position: relative;
        top: .1em;
        margin-right: 4px;
    }
    > span:nth-child(4){
        font-size: 16px;
    }
}

.Tips{
    width: 100%;
    color:#979797;
    font-size: 12px;
    line-height: 20px;
    margin: 0 auto;
    text-align: left;
    margin-top: 20px;
    li{
        position: relative;
        padding-left: 1em;
        &::before{
            content: '*';
            position: absolute;
            left: 0;
            top: 2px;
            margin-right: 5px;
        }
        a{
            text-decoration: underline;
            margin-left: 4px;
        }
    }
    span{
        color: var(--color-primary);
    }
}

.PayContent{
    position: relative;
    width: 100%;
    height: 100%;
    .iframePlaceholderWrap{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 200px;
        height: 200px;
        .iframePlaceholder{
            width: 200px;
            height: 200px;
            background: center / cover no-repeat;
            &.alipay{
                background-image: url('~@assets/images/alipay_placeholder.png');
            }        
        }
    }
    
    .payFrame{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 200px;
        height: 200px;
        overflow: hidden;
        border: none;
    }
}