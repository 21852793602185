.RefImgPopover{
    :global(.ant-popover-inner){
        padding: 0;
        box-shadow: none;
        background-color: transparent
    }
}

.RefImg{
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-8px, -50%);
    width: 15px;
    height: 14px;
    background: url('~@assets/images/icon_help.svg') center / cover no-repeat;
}