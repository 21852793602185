.app_loading{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,.8);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .app_loading_icon{
        >div{
            font-size: 60px;
        }
    }
    .app_loading_tip{
        margin-top: 20px;
        font-size: 26px;
        color: var(--color-primary);
        // color: #FFFFFF;
    }
}

// .loading {
//     position: absolute;
//     background-color: rgba($color: (#000000), $alpha: .6);
//     z-index: 9999;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     flex-wrap: wrap;
//     align-items: center;
//     p {
//       width: 100%;
//       text-align: center;
//       margin-top: 20px;
//       font-size: 20px;
//       color: #fff;
//     //   @media screen and (max-width: 1200px) {
//     //     font-size: 12px;
//     //   }
//     }
//     .parentBox {
//         display: flex;
//         // height: 210px;
//         // width: 120px;
//         height: 168px;
//         width: 96px;
//         .scalableBox {
//           width: 100%;
//           height: 100%;
//         }
//         .apringBox {
//           transition: stroke 0.3s;
//         }
      
//         .apwormOneBox,
//         .apwormTwoBox {
//           animation-duration: 3s;
//           animation-iteration-count: infinite;
//         }
//         .apwormTwoBox {
//           animation-name: worm2;
//           visibility: hidden;
//         }
//         .apwormOneBox {
//           animation-name: worm1;
//         }
//       }
      
//       @media (prefers-color-scheme: dark) {
//         :root {
//           --bg: hsl(var(--hue), 10%, 10%);
//           --fg: hsl(var(--hue), 10%, 90%);
//         }
      
//         .apringBox {
//           stroke: hsla(var(--hue), 10%, 90%, 0.9);
//         }
//       }
      
//       @keyframes worm1 {
//         from {
//           animation-timing-function: ease-in-out;
//           stroke-dashoffset: -87.96;
//         }
      
//         20% {
//           animation-timing-function: ease-in;
//           stroke-dashoffset: 0;
//         }
      
//         60% {
//           stroke-dashoffset: -791.68;
//           visibility: visible;
//         }
      
//         60.1%,
//         to {
//           stroke-dashoffset: -791.68;
//           visibility: hidden;
//         }
//       }
      
//       @keyframes worm2 {
//         from,
//         60% {
//           stroke-dashoffset: -87.96;
//           visibility: hidden;
//         }
      
//         60.1% {
//           animation-timing-function: cubic-bezier(0, 0, 0.5, 0.75);
//           stroke-dashoffset: -87.96;
//           visibility: visible;
//         }
      
//         77% {
//           animation-timing-function: cubic-bezier(0.5, 0.25, 0.5, 0.88);
//           stroke-dashoffset: -340;
//           visibility: visible;
//         }
      
//         to {
//           stroke-dashoffset: -669.92;
//           visibility: visible;
//         }
//       }


// }

// .loadingBox {
//     width: 3rem;
//     height: 3rem;
//     border-radius: 12px;
//     // background-color: #f3f2f2
// }


