.radio_group{
    width: auto;
    background: #ffffff;
    border-radius: 12px;
    padding: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.radio_popover{
    :global(.ant-popover-inner){
        padding: 8px 12px;
    }
}

.radio_warp{
    padding: 0 4px;
    min-width: 58px;
    height: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: transparent;
    transition: background-color .2s;
    cursor: pointer;
    &:not(:last-child){
        margin-right: 4px;
    }
    &_checked:not(&_disabled), &:not(&_disabled):hover{
        background-color: #edf0ff;
    }
    &_disabled{
        opacity: .6;
        filter: grayscale(100%);
        cursor: not-allowed;
    }
}

.radio_icon{
    display: inline-block;
    width: 24px;
    height: 24px;
    background: center / cover no-repeat;
}

.radio_label{
    font-size: 12px;
    line-height: 1;
}