.Container{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 50px 0 10px;
}

.MaskDeskWrap{
    position: relative;
    height: 100%;
}

.MaskDesk{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
}

.ToolBox{
    position: absolute;
    width: 100%;
    top: -10px;
    right: 0;
    transform: translate(0, -100%);
    &.autoWidth{
        width: auto;
    }
}

.Loading{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .6);
}