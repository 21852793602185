.DragAndScale{
    display: flex;
    > button{
        margin-left: 6px;
    }
    .scale_label{
        border-top: 1px solid #f4f4f4;
        border-bottom: 1px solid #f4f4f4;
        background-color: #f4f4f4;
        width: 60px;
        height: 24px;
        line-height: 24px;
        text-align: center;
    }
    :global(.ant-btn-default){
        border-color: #f4f4f4;
        &:disabled{
            background-color: #d9d9d9;
            border-color: #d9d9d9;
        }
    }
}

.PopoverWrap{
    .ant-popover-inner{
        padding: 10px 8px;
    }
}
.PopoverContent{
    display: flex;
    flex-direction: column;
    .optionItem{
        display: block;
        width: 87px;
        height: 20px;
        color: #1d1b1b;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        border-radius: 8px;
        background-color: #FFFFFF;
        cursor: pointer;
        &:hover{
            background-color: #eff2fd;
        }
        &:not(:last-child){
            margin-bottom: 4px;
        }
        &.optionItem_selected{
            background-color: #eff2fd;
        }
    }
}