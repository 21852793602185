.ModelFilterSelect{
    .ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
        color: #6d6d6d;
    }
    .ant-col > label{
        display: inline-block;
        height: 24px;
        line-height: 24px;
        margin-right: 10px;
        &:not(:first-child){
            margin-left: 20px;
        }
    }
    .ant-select-multiple.ant-select-sm{
        font-size: 12px;
        .ant-select-selection-overflow{
            flex-wrap: nowrap;
        }
        .ant-select-selection-item{
            margin-inline-end: 2px;
        }
    }
    .ant-select-selection-overflow-item{
        &:not(:first-child){
            margin-left: 2px;
        }
    }
    .ModelFilterSelect_tag{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0 4px;
        font-size: 12px;
        height: 100%;
        background-color: #F0F0F0;
    }
    .checkbox-wrap{
        display: flex;
        align-items: center;
        padding: 12px;
        background:#ffffff;
        border-radius:8px;
        margin-bottom: 6px;
        &:not(:last-child){
            margin-right: 16px;
        }
        img{
            width: 18px;
            height: 18px;
            object-fit: contain;
            margin-right: 16px;
        }
    }
}

.custom_select_item{
    position: relative;
    display: flex;
    min-height: 32px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.88);
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5714285714285714;
    box-sizing: border-box;
    cursor: pointer;
    transition: background 0.3s ease;
    border-radius: 4px;
    &.active, &:hover{
        color: rgba(0,0,0,.88);
        font-weight: 600;
        background-color: #e6f4ff;
    }
    &_content{
        flex: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    &_state{
        flex: none;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #5AA0FF;
    }
}