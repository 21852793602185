.RcSlider {
    min-width: 100px;
    p {
        font-size: 12px;
        color: #1d1b1b;
        span:nth-child(2) {
            float: right;
        }
    }
    :global(.ant-slider) {
        margin: 5px 0 0;
    }
}
.tracks{
    background-color: #d6d6d6;
}

.Slider{
    &:hover .track_paint{
        background-color: var(--color-primary);
    }
    .track_erase{
        background-color: rgba(254, 41, 94, 0.2);
    }
    &:hover .track_erase{
        background-color: rgba(254, 41, 94, 1);
    }
    .handle_erase::after{
        box-shadow: 0 0 0 2px rgba(254, 41, 94, 0.2);
    }
    &:hover .handle_erase::after{
        box-shadow: 0 0 0 2px rgba(254, 41, 94, 1);
    }
    .handle_erase:active::after {
        box-shadow: 0 0 0 4px rgba(254, 41, 94, 1);
    }
}