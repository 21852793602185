@import '@view/comom.scss';
.Head {
    height: 60px;
    font-size: 20px;
    background-color: #fff;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ai_tab {
        position: relative;
        &::before {
            position: absolute;
            top: -10px;
            right: -26px;
            content: '';
            display: block;
            width: 26px;
            height: 12px;
            z-index: 20;
            background: url('~@assets/images/vip_conner.png') center / cover no-repeat;
        }
    }
    .head_left {
        position: relative;
        float: left;
        display: flex;
        align-items: center;
        &.showBetaIcon::after{
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(100%, -48%);
            width: 37.5px;
            height: 20px;
            background: url("~@assets/images/Beta_Icon.svg") center / cover no-repeat;            
        }
        .logo2 {
            display: block;
            width: 240px;
            height: 60px;
            cursor: pointer;
            background: url("~@assets/images/01_logo.svg") center / cover no-repeat;
            background-position: center 41%;
            &.cloth_logo{
                width: 240px;
                height: 60px;
                &::after{ padding-bottom: 23%; }
                background-image: url("~@assets/images/02_logo.svg");
            }
            &.mc_logo{
                width: 240px;
                height: 60px;
                &::after{ padding-bottom: 18.6%; }
                background-image: url("~@assets/images/03_logo.svg");
            }
        }
        .select-type-name{
            display: inline-block;
            font-size: 18px;
            font-weight: 500;
            // height: 33px;
            // line-height: 33px;
            display: inline-flex;
            align-items: center; 
            margin-left: 10px;
            transform: translate(0, 1px);      
        }
    }
    .head_center{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        width: 60%;
        flex: none;
    }
    .head_right {
        float: right;
        flex: 1;
        width: 100%;
        min-width: 150px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .selected {
        color: $main-primary-color;
    }
    .btn_r {
        height: 32px;
        min-width: 91px;
        line-height: 30px;
        width: 110px;
        text-align: center;
        font-size: 14px;
        border-radius: 14px;
        color: #fff;
        margin-left: 1rem;
        cursor: pointer;
        box-shadow: none;
    }
    
}

.LoginHead {
    position: relative;
    z-index: 100;
    .ant-menu-overflow-item {
        position: relative;
        z-index: 100;
        margin-right: .5rem;
    }
    .ant-menu-horizontal {
        border-bottom: 0;
    }
    .ant-menu-light, .ant-menu-light>.ant-menu {
        background: none;
    }
    .ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected {
        font-weight: bold;
    }
    .submenu-title-wrapper{
       .anticon-down{
        margin-left: 10px;
       }
    }
    .tips {
        position: absolute;
        top: 50px;
        right: 20px;
        font-size: 12px;
        color: #fff;
    }
}
.ant-menu-submenu.menu-item-overflow-visible{
    .ant-menu-item{
        overflow: visible;
    }
    .ant-menu-vertical.ant-menu-sub{
        width: 130px;
        min-width: 130px;
    }
}
.ant-menu-item{
    .ant-menu-title-content{
        .not-ready-item{
            position: relative;
            img{
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(70%, -60%);
            }
        }
    }
}
