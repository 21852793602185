.task-list-item{
    cursor: pointer;
    // width: 1090px;
    height: 94px;
    border-radius: 12px;
    background-color: #FFF;
    &:not(:last-child){
        margin-bottom: 8px;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 60px;
    .ant-space{
        position: relative;
        width: 20%;
        height: 30px;
        line-height: 30px;
        .ant-space-item{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center; 
        }
        &:nth-child(1){
            height: 72px;
        }
        &:not(:nth-child(1)) {
            .ant-space-item{
                & > span{
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 80%;
                }
            }
        }        
        &:last-child{
            .ant-space-item:first-child{
                width: 30%;
                & > span{
                    width: 100%;
                }
            }
            .ant-space-item:last-child{
                width: 60%;
            }
        }
    }
    .item-cover{
        width: 72px;
        height: 72px;
        border-radius: 8px;
        object-fit: cover;
        background: rgba($color: #000000, $alpha: .5);
    }
    .item-rebuild{
        margin-left: 5px;
        font-size: 12px;
        height: 28px;
        line-height: 27px;
        padding: 0 20px;
        border-radius: 8px;
    }
}