.Container{
    width: 100%;
    height: 100%;
}

.Content{
    height: 100%;
    display: flex;
    .ContentUpload{
        flex: 1;
        max-width: 840px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        padding: 20px 0;
        margin-left: 8%;
        .SkirtModule_upload{
            position: relative;
            width: 95%;
            flex: 1;
            // max-height: 345px;
            max-height: calc((100% - 32px - 20px) / 2);
            margin: 0 auto;
            // border-radius: 28px;
            &:nth-child(2){
                margin-top: 28px;
            }
            &:hover{
                .UploadExample{
                    background-color: #E8E9EE;
                    color: var(--color-primary);
                }
            }
        }
        .SkirtModule_mask{
            position: relative;
            width: 95%;
            flex: 1 1;
            // max-height: 345px;
            max-height: calc((100% - 32px - 20px) / 2);
            margin: 0 auto;
            border-radius: 28px;
            &.cloth_type_2{
                max-height: none;
            }
            &:not(:first-child){
                margin-top: 32px;
            }
            &:hover{
                .UploadExample{
                    background-color: #E8E9EE;
                    color: var(--color-primary);
                }
            }
        }
        .UploadExample{
            cursor: pointer;
            position: absolute;
            left: 50%;
            bottom: 2%;
            transform: translate(-50%, 0);
            width: 86px;
            height: 24px;
            line-height: 24px;
            color: var(--color-85);
            border-radius: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            >span:last-child{
                margin-left: 4px;
            }
        }
    }
    .ContentSample{
        flex: none;
        width: 30%;
        height: 100%;
        margin-left: 10px;
        &.hidden{
            width: 0;
            min-width: 0;
            margin-left: 0;
        }
    }
}

.ExampleContent{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    &.vertical{
        margin: 0 auto;
        width: fit-content;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        .ExampleTips{display: none;}
        >div {
            flex: none;
            height: calc((100% - 10px) / 2);
            // max-height: 345px;
            max-height: calc((100% - 32px - 20px) / 2);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            background-color: #F4F7FF;
            border-radius: 12px;
            padding: 12px;
            &:nth-child(2){
                margin-top: 32px;
            }
            img {
                width: auto;
                height: calc(100% - 30px - 24px);
                transform: translate(4px, 0);
            }
        }
    }
    &.horizontal{
        flex-direction: row;
        padding: 40px 0;
        >div{
            width: 40%;
            max-width: 272px;
        }
        .ExampleTitle{
            margin-bottom: 12px;
        }
    }
    .ExampleTitle{
        font-size: 16px;
        line-height: 30px;
        font-weight: bold;
        color: #1D1B1B;
        text-align: center;
    }
    .ExampleTips{
        margin-top: 10px;
        display: flex;
        >span{
            flex: 1;
            font-size: 14px;
            text-align: center;
            white-space: nowrap;
            width: calc(100% / 3);
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.UploadBox{
    height: 100%;
    display: flex;
    flex-direction: column;
    :global(.ant-spin-nested-loading), :global(.ant-spin-container){
        height: 100%;
    }
    .Upload{
        height: 100%;
        &:global(.ant-upload-wrapper.ant-upload-picture-card-wrapper){
            :global(.ant-upload.ant-upload-select) {
                width: 100%;
                height: 100%;
                margin: 0;
                background-color: transparent;
                overflow: hidden;
                // border-radius: 12px;
                border-radius: 28px;
                // border-color: #d9d9d9;
                &:hover{
                    background-color: var(--color-98);
                }
            }
        }
    }
}

.UploadContent{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // .UploadPreview{
    //     width: 100%;
    //     height: 100%;
    //     object-fit: contain;
    // }
    .UploadHandle{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        >img{
            margin-bottom: 28px;
        }
        .UploadBtn{
            width: 228px;
            height: 44px;
            font-size: 20px;
            line-height: 44px;
            margin-bottom: 20px;
        }
        .UploadTip{
            font-size: 24px;
            line-height: 1;
            font-weight: bold;
            color: var(--color-primary);
            margin-bottom: 12px;
        }
        .UploadTip2{
            font-size: 12px;
            height: 18px;
            line-height: 18px;
            margin-bottom: 16px;
            color: rgba(0, 0, 0, .6);
        }
        .UploadSample{
            >p{
                white-space: nowrap;
                font-size: 12px;
                height: 18px;
                line-height: 18px;
                margin-bottom: 12px;
                color: rgba(0, 0, 0, .6);
            }
            > ul{
                display: flex;
                justify-content: center;
                margin-bottom: 40px;
                li{
                    cursor: pointer;
                    width: 52px;
                    height: 70px;
                    border-radius: 10px;
                    overflow: hidden;
                    img{
                        width: 100%;
                    }
                    &:not(:last-child){
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
