.Container{
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    overflow: hidden;
}

.DeskWrap{
    position: relative;
    height: 100%;
    .ImgDesk{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .FrontImg{
            position: relative;
            margin-right: 20px;
            :global(.ant-image){
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
            .ToolBox{
                position: absolute;
                width: auto;
                top: -10px;
                right: 0;
                transform: translate(0, -100%);
            }
        }
        .BackImg{
            position: relative;
            width: 200px;
        }
    }
}

.UploadBackImgContainer{
    &:global(.ant-upload-wrapper.ant-upload-picture-card-wrapper){
        width: 100%;
        :global(.ant-upload.ant-upload-select){
            box-sizing: content-box;
            width: 100%;
            height: auto;
            background-color: transparent;
            margin: 0;
            margin-inline-end: 0;
            border-radius: 12px;
        }
    }
    .UploadBackImg{
        width: 200px;
        height: 300px;
        color: #8D8D8D;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        line-height: 22px;
        span:nth-child(1){
            font-size: 20px;
        }
        p:nth-child(2) {
            margin: 5px 0;
        }
    }
}

.UploadWrap{
    display: inline-block;
    width: 100%;
    height: 100%;
    :global(.ant-upload.ant-upload-drag){
        display: inline-block;
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        text-align: left;
    }
    :global(.ant-upload.ant-upload-btn){
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 0;
    }
    :global(.ant-upload-drag .ant-upload-drag-container){
        display: inline-block;
        width: 100%;
        height: 100%;
    }
    :global(.ant-upload-drag .ant-upload){
        padding: 0;
    }
}