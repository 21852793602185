.ShareBody{
    width: 320px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ShareBox{
    width: 180px;
    height: 180px;
    background:#ffffff;
    border:1px solid;
    border-color:#d6d6d6;
    border-radius:10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ShareTips{
    margin-top: 16px;
    color:#1d1b1b;
    font-size:12px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before{
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        background: url("~@assets/images/icon_WeChat.png") center / cover no-repeat;
        margin-right: 8px;
    }
}