.ZoomBg{
    z-index: 1;
    width: 225px;
    position: absolute;
    left: 55%;
    top: -10px;
    transform: translate(-50%,-100%);
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 12px;
    border: 1px solid rgba(35, 35, 35, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    color: #FFFFFF;
    @media screen and (max-width: 1080px){
        left: 0;
        transform: translate(0, -100%);
    }
}

.ZoomSlider{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    color: #FFFFFF;
    >span{
        line-height: 1;
    }
    >div{
        flex: 1;
        margin: 8px 14px;
        :global(.ant-slider-rail){
            background-color: rgba(221, 231, 255, 0.5);
        }
        &:hover :global(.ant-slider-rail){
            background-color: rgba(221, 231, 255, 0.5);
        }
    }
}