.m_box_item_comp {
    margin: 0 .6rem .3rem 0.3rem;
    margin-bottom: 40px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    .item_title {
        color:#1d1b1b;
        font-size:14px;
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .favor_icon{
            display: block;
            width: 28px;
            height: 28px;
            background: url('~@assets/images/bookmark_checked.svg') center / cover no-repeat;
        }
        &.item_title_ {
            color:#979797;
            font-size:12px;
            margin-top: 0;
        }
        img {
            cursor: pointer;
            height: 100%;
        }
        span {
            display: inline-block;
            width: calc(100% - 20px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
        }
    }
    .item_input{
        width: 100%;
    }
    
    .m_box_item {
        position: relative;
        width: 100%;
        height: 2.1rem;
        border-radius: .16rem;
        position: relative;
        cursor:pointer;
        &.m_box_item_comp_item {
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.1);
            border-radius: 0.16rem;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }
        &.favoriteBox{
            border: 1px solid #EDF0FF;
            background-color: transparent;
            &:hover{
                background-color: #EDF0FF;
            }
            &:hover::after {
                display: none;
            }
        }
        .main_img {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 0.16rem;
            box-shadow:0px 5px 5px rgba(214, 214, 214, 0.6);
            background-color: rgba(255,255,255,0.5);
            object-fit: cover;
            object-position: 50% 0;
        }
        .main_img_ {
            object-fit: cover;
            object-position: 50% 0;
            transform: scale(2);
            transform-origin: center center;
        }
        .empty_img{
            width: 37%;
            &::after{
                content: "";
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: 121.8%;
            }
            background: url('~@assets/images/favorite_empty.svg') center / cover no-repeat;
        }
        .m_box_item_select {
            z-index: 1;
            position: absolute;
            top: .1rem;
            left: .15rem;
        }
        &:hover::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 0.16rem;
            background: rgba($color: #000, $alpha: .1);
            pointer-events: none;
        }
        .del_btn {
            position: absolute;
            right: 5px;
            top: 5px;
            display: block;
            width: 28px;
            height: 28px;
            z-index: 10;
            visibility: hidden;
            background: url('~@assets/images/Remove_Normal.svg') center / cover no-repeat;
            transition: background-image .2s;
            &:hover{
                background-image: url('~@assets/images/Remove_hover.svg')
            }
            &:active{
                background-image: url('~@assets/images/Remove_Press.svg')
            }
        }
        .check_view  {
            width: 100px;
            position: absolute;
            bottom: 20px;
            height: 25px;
            line-height: 25px;
            border-radius: .10rem;
            text-align: center;
            background: #fff;
            z-index: 10;
            visibility: hidden;
        }
        &:hover .del_btn {
            visibility: visible;
        }
        &:hover .check_view {
            visibility: visible;
        }
    }

    .video_flag{
        position: absolute;
        left: 12px;
        top: 12px;
        width: 27px;
        height: 20px;
        background: url("~@assets/images/icon_video.svg") center / cover no-repeat;
    }
}