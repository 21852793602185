.login_box {
    display: flex;
    width: 95vw;
    max-width: 956px;
    min-height: 560px;
    overflow: hidden;
    .RightBox{
        width: 100%;
        min-height: 560px;
        padding: 55px 0;
    }
    .RightContent{
        min-height: 560px;
        max-width: 422px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .r_title {
            font-weight:700;
            color:#1d1b1b;
            font-size: 32px;
            margin-bottom: 30px;
        }
        .r_footer{
            margin-top: 10px;
            .r_p {
                text-align: left;
                .span_normal{
                    font-size: 12px;
                    color: #979797;
                }            
                .span_click {
                    color: var(--color-primary);
                    cursor: pointer;
                    font-weight: bold;
                    margin: 0 2px;
                }
            }
            .notice_text{
                margin-top: 5px;
                height: 20px;
                line-height: 20px;
                font-size: 12px;
                color: #999;
                text-align: left;
                & + .r_btn{
                    margin-top: 5px;
                }
            }
        }
        .r_content{
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
        }
    }
}

.LoginTabs{
    :global(.ant-tabs-top >.ant-tabs-nav::before),
    :global(.ant-tabs-bottom >.ant-tabs-nav::before),
    :global(.ant-tabs-top >div>.ant-tabs-nav::before),
    :global(.ant-tabs-bottom >div>.ant-tabs-nav::before) {
        border: 0;
    }
}