
.image_preview_custom_describe {
    flex: none;
    z-index: 1;
    position: relative;
    width: 254px;
    height: 100%;
    background-color: #F2F2F2;
    padding: 10px;
    border-radius: 0 16px 16px 0;
    .describe_title{
        text-align: left!important;
        color: #1d1b1b;
        font-weight: 700;
        margin-bottom: 5px;
    }
    .describe_bottom {
        text-align: left!important;
        margin-bottom: 20px;
    }
    .title_box{
        padding: 0 10px;
        margin-bottom: 20px;
    }
    .top_box{
        height: calc(100% - 200px);
        padding: 0 10px;
        .describe_ref_img{
            margin-bottom: 20px;
            display: flex;
            align-items: stretch;
            >img{
                max-width: 100%;
                max-height: 200px;
                object-fit: contain;
                background-color: rgba(0,0,0,.2);
            }
            &.multiple_img{
                >img{
                    max-width: 50%;
                }
            }
        }
    }
    .bottom_box {
        user-select: none;
        position: absolute;
        bottom: 10px;
        background: #fff;
        width: 234px;
        height: 124px;
        border-radius:10px;
        padding: 16px 18px;
        > p {
            font-weight: 500;
            text-align: left;
        }
        .describe_score_item {
            margin-top: 16px;
            padding: 0;
            display: flex;
            justify-content: center;
            > span {
                width: 48px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius:8px;
                padding: 8px 0;
                cursor: pointer;
                &:not(:last-child){
                    margin-right: 16px;
                }
                > img {
                    width: 24px;
                    height: 24px;
                }
                > span {
                    white-space: nowrap;
                    display: inline-block;
                    width: 36px;
                    text-align: center;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }
}

.discontent_count {
    padding: 40px 40px 0;
    border-radius: 0 0 10px 10px;
    .discontent_count_select {
        display: inline-block;
        width: 200px;
        height: 44px;
        line-height: 44px;
        border-radius:29px;
        border:1px solid #e6e8f2;
        text-align: center;
        font-size:16px;
        cursor: pointer;
        margin-bottom: 30px;
        &:hover {
            border:1px solid #0e37ff;
            color: #0e37ff;
        }
    }
    .discontent_count_selected {
        border:1px solid #0e37ff;
        color: #0e37ff;
    }
    .discontent_count_p {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}