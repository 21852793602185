.Container{
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    border: 1px dashed #d9d9d9;
    border-radius: 28px;
    overflow: hidden;
    &:hover{
        background-color: var(--color-98);
    }
}

.MaskDeskWrap{
    position: relative;
    height: 100%;
}
.MaskDesk{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    img:nth-child(1) {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    img:nth-child(2){
        position: absolute;
        right: 11px;
        top: 20px;
        cursor: pointer;
    }
}

.ToolBox{
    position: absolute;
    top: 16px;
    right: 16px;
}

.Loading{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .6);
}

.UploadWrap{
    display: inline-block;
    width: 100%;
    height: 100%;
    :global(.ant-upload.ant-upload-drag){
        display: inline-block;
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        text-align: left;
    }
    :global(.ant-upload.ant-upload-btn){
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 0;
    }
    :global(.ant-upload-drag .ant-upload-drag-container){
        display: inline-block;
        width: 100%;
        height: 100%;
    }
    :global(.ant-upload-drag .ant-upload){
        padding: 0;
    }
}