.Container{
    position: relative;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    .SliderBar{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 96px;
        height: 26px;
        border-radius: 18px;
        // border: 1px solid var(--color-primary);
        background-color: var(--color-98);
        transition: left .5s;
    }
    .SelectItem{
        position: relative;
        cursor: pointer;
        box-sizing: border-box;
        width: 96px;
        height: 26px;
        border-radius: 18px;
        border: 1px solid transparent;
        color: #707070;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        transition: color .5s;
        &.actived{
            color: var(--color-primary);
        }
    }
}