.edit_right_r {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
    .btn_ {
        opacity: 1;
        pointer-events: all;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 8px;
        padding: 5px 13px;
        font-size: 12px;
        width: 100px;
        height: 30px;
        margin-bottom: 20px;
        cursor: pointer;
        border: none;
        user-select: none;
        transition: background-color .2s;
        .img {
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 8px;
            background: center / contain no-repeat;
        }
        span{
            flex: 1;
            text-align: justify;
            display: inline-block;
            height: 18px;
            &:after {
                content:'';
                width: 100%;
                display: inline-block;
                overflow: hidden;
                height: 0;
            }
        }
        .img.disabled{ display: none; }
        .img.normal{ display: block; }
        .img.active{ display: none; }
        &.disabled{
            background:#ffffff;
            color:#979797;
            pointer-events: none;
            .img.disabled{ display: block; }
            .img.normal{ display: none; }
            .img.active{ display: none; }
        }
        &:not(.disabled):hover{
            background:#ffffff;
            color:var(--color-primary);
            .img.disabled{ display: none; }
            .img.normal{ display: none; }
            .img.active{ display: block; }
        }
        &:not(.disabled):active{
            background: #d6d6d6;
            color:var(--color-primary);
            .img.disabled{ display: none; }
            .img.normal{ display: none; }
            .img.active{ display: block; }
        }
    }
    .vip_btn{
        padding: 0 0;
        position: relative;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        width: 100px;
        height: 30px;
        border-radius:8px;
        background-color: #FFFFFF;
        border: none;
        transition: all .2s;
        cursor: pointer;
        overflow: hidden;
        &.disabled{
            color:#979797;
            pointer-events: none;
            border-color: #FFFFFF;
        }
        &:not(.disabled):hover{
            background:#ffffff;
            color:var(--color-primary);
        }
        &:not(.disbaled):active{
            background:#d6d6d6;
            color:var(--color-primary);
        }
    }
}
.tool_box{
    width: 100px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

.ReuploadBox{
    z-index: 3;
    position: absolute;
    top: 10px;
    left: -66px;
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(65, 65, 65, .6);
    &:hover{
        background-color: rgba(29, 27, 27, .45);
    }
    &:active{
        background-color: rgba(29, 27, 27, .8);
    }
    .Uploader{
        display: block;
        width: 100%;
        height: 100%;
        :global(.ant-upload.ant-upload-select) {
            width: 100%;
            height: 100%;
            border: none;
        }
        .UploaderIcon{
            width: 20px;
            height: 20px;
            background: url('~@assets/images/icon_upload_white.svg') center / cover no-repeat;
        }
    }
}