.MenuContianer{
    padding: 10px;
    background: rgba(0,0,0,.6);
    border-radius: 12px;
    backdrop-filter:blur(1px);
}
.LargeMenuItem{
    width: 123px;
}
.MenuItem{
    margin-right: 5px;
    min-width: 90px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 300;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 16px;
    transition: all .2s;
    &.disabled{
        cursor: not-allowed;
        font-weight: 300!important;
        color: #cbcbcb!important;
        .Icon.aiEditor{ background-image: url('~@assets/images/icon_aiEditor_disabled.svg')!important; }
    }
    &:not(:last-child){
        margin-bottom: 8px;
    }
    .Icon{
        width: 16px;
        height: 16px;
        margin-right: 6px;
        background: center / cover no-repeat;
        &.changeModel{ background-image: url('~@assets/images/icon_change_model.svg'); } // 更改背景
        &.changeBg{ background-image: url('~@assets/images/icon_change_bg.svg'); } // 更改模特
        &.changeAction{ background-image: url('~@assets/images/icon_change_model.svg'); } // 更换动作
        &.replica{ background-image: url('~@assets/images/icon_replica.svg'); } // 复刻
        &.rename{ background-image: url('~@assets/images/icon_rename.svg'); } // 重命名
        &.delete{ background-image: url('~@assets/images/icon_delete.svg'); } // 删除
        &.setCover{ background-image: url('~@assets/images/icon_rename.svg'); } // 设置封面
        &.aiEditor{ background-image: url('~@assets/images/icon_aiEditor.svg'); } // ai编辑
        &.share{ background-image: url('~@assets/images/icon_share.svg'); } // 分享
        &.send{ background-image: url('~@assets/images/icon_send.svg'); } // 发送到真人图
        &.bookmark{ background-image: url('~@assets/images/icon_bookmark.svg'); } // 收藏
        &.bookmarked{ background-image: url('~@assets/images/icon_bookmarked.svg'); } // 收藏
        &.download{ background-image: url('~@assets/images/icon_download.svg'); } // 下载
    }
    .VipRequired{
        margin-left: 6px;
        width: 23px;
        height: 14px;
        background: url('~@assets/images/vip_required.svg') center / cover no-repeat;
    }
    &:hover{
        font-weight: 700;
        color: #FFFFFF;
        .Icon{
            &.changeModel{ background-image: url('~@assets/images/icon_change_model_hover.svg'); }
            &.changeBg{ background-image: url('~@assets/images/icon_change_bg_hover.svg'); }
            &.changeAction{ background-image: url('~@assets/images/icon_change_model_hover.svg'); }
            &.replica{ background-image: url('~@assets/images/icon_replica_hover.svg'); }
            &.rename{ background-image: url('~@assets/images/icon_rename_hover.svg'); }
            &.delete{ background-image: url('~@assets/images/icon_delete_hover.svg'); }
            &.setCover{ background-image: url('~@assets/images/icon_rename_hover.svg'); }
            &.aiEditor{ background-image: url('~@assets/images/icon_aiEditor_hover.svg'); }
            &.share{ background-image: url('~@assets/images/icon_share_hover.svg'); }
            &.send{ background-image: url('~@assets/images/icon_send_hover.svg'); }
            &.bookmark{ background-image: url('~@assets/images/icon_bookmark_hover.svg'); } // 收藏
            &.bookmarked{ background-image: url('~@assets/images/icon_bookmarked.svg'); } // 收藏
            &.download{ background-image: url('~@assets/images/icon_download_hover.svg'); } // 下载
        }
    }
    &:active{
        font-weight: 500;
        color:#e0e0e0;
        .Icon{
            &.changeModel{ background-image: url('~@assets/images/icon_change_model_press.svg'); }
            &.changeBg{ background-image: url('~@assets/images/icon_change_bg_press.svg'); }
            &.changeAction{ background-image: url('~@assets/images/icon_change_model_press.svg'); }
            &.replica{ background-image: url('~@assets/images/icon_replica_press.svg'); }
            &.rename{ background-image: url('~@assets/images/icon_rename_press.svg'); }
            &.delete{ background-image: url('~@assets/images/icon_delete_press.svg'); }
            &.setCover{ background-image: url('~@assets/images/icon_rename_press.svg'); }
            &.aiEditor{ background-image: url('~@assets/images/icon_aiEditor_press.svg'); }
            &.share{ background-image: url('~@assets/images/icon_share_press.svg'); }
            &.send{ background-image: url('~@assets/images/icon_send_press.svg'); }
            &.bookmark{ background-image: url('~@assets/images/icon_bookmark_press.svg'); } // 收藏
            &.bookmarked{ background-image: url('~@assets/images/icon_bookmarked.svg'); } // 收藏
            &.download{ background-image: url('~@assets/images/icon_download_press.svg'); } // 下载
        }
    }
}

.DownloadMenu{
    width: 140px;
    padding: 8px;
    background-color: rgba(29, 27, 27, 0.8);
    border-radius: 12px;
    margin-right: 10px;
    &.hdPayed{
        width: 80px;
    }
    .MenuItem1{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // width: 123px;
        height: 26px;
        border-radius: 8px;
        color:#ffffff;
        font-size: 12px;
        line-height: 26px;
        padding: 0 8px;
        text-align: left;
        box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .1);
        white-space: nowrap;
        cursor: pointer;
        img{
            width: 12px;
            margin-left: 4px;
        }
        &:not(:last-child){
            margin-bottom: 8px;
        }
        &:hover{
            background-color: rgba(235, 240, 255, 1);
            color:#1d1b1b;
        }
    }
}


.m_box_item_comp {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    cursor:pointer;
    .m_box_item {
        position: relative;
        width: 100%;
        height: 2.1rem;
        border-radius: .16rem;
        position: relative;
        overflow: hidden;
        > img, > video {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0) scale3d(1,1,1);
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: transform .2s;
            will-change: transform;
        }
        .HDRibbon{
            position: absolute;
            text-align: center;
            left: 10px;
            top: 10px;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 200px;
            height: 30px;
            font-size: 12px;
            line-height: 24px;
            background-color: var(--color-primary);
            color: #FFFFFF;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
        .illegal_warning{
            width: 100%;
            font-size: 12px;
            text-align: center;
            color: #FFF;            
            position: absolute;
            left: 50%;
            top: 70%;
            transform: translate(-50%, -50%) scale(.9);
        }
        .m_box_img {
            width: 2.1rem;
            height: 2.1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: auto;
                height: auto;
                margin: 0 auto;
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
                border-radius: 0.12rem;
            }
        }
        .m_box_item_menu_content {
            z-index: 1;
            position: absolute;
            width: 12px;
            height: 26px;
            top: 4%;
            right: 4%;
            display: flex;
            justify-content: center;
            align-items: center;
            visibility: hidden;
            .m_box_item_menu_img {
                cursor: pointer;
                width: 12px;
                height: 26px;
                margin: 0 auto;
                background: rgba(29, 27, 27, 0.3);
                transition: background-image .2s;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                >i{
                    display: block;
                    width: 4px;
                    height: 4px;
                    border-radius: 100%;
                    background-color: #FFFFFF;
                    &:not(:last-child){
                        margin-bottom: 3px;
                    }
                }
            }
        }
        .m_box_item_select {
            z-index: 1;
            position: absolute;
            top: .1rem;
            left: .15rem;
        }
        &:hover > img, &.download_open > img, &.dropdown_open > img{
            transform: translate3d(-50%, -50%, 0) scale3d(1.05, 1.05, 1);
        }
        &:hover .m_box_item_menu_img, &.download_open .m_box_item_menu_img, &.dropdown_open .m_box_item_menu_img{
            background-image: url('~@assets/images/icon_more_checked.svg');
        }
        &:hover .m_box_item_float, &.download_open .m_box_item_float, &.dropdown_open .m_box_item_float{
            visibility: visible;
        }
        &:hover .m_box_item_menu_content, &.download_open .m_box_item_menu_content, &.dropdown_open .m_box_item_menu_content{
            visibility: visible;
        }
        // &::before{
        //     z-index: 1;
        //     content: '';
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     display: block;
        //     width: 100%;
        //     height: 100%;
        //     border-radius: .08rem;
        //     transition: background .2s;
        //     pointer-events: none;
        // }
        // &:hover::before, &.download_open::before, &.dropdown_open::before{
        //     background: rgba($color: #000, $alpha: .4);
        // }
    }
    .m_box_item_comp_item {
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.1);
        border-radius: 0.16rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        .m_box_item_float{
            z-index: 1;
            visibility: hidden;
            // visibility: visible;
            position: absolute;
            width: 100%;
            bottom: .2rem;
        }
    }
    .video_flag{
        position: absolute;
        left: 12px;
        top: 14px;
        width: 32px;
        height: 22px;
        border-radius: 12px;
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        // background-color: rgba(29, 27, 27, 0.3);
        background: url("~@assets/images/HD Preview.svg") center / cover no-repeat;
    }
}