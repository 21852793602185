.Container{
    position: relative;
    width: 100%;
    transition: width 250ms, margin 250ms;
    &::after{
        content: '';
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }
}