.FillLight {
    .edit_center_tip {
        color: #4f4f4f;
        margin-bottom: 20px;
        font-size:12px;
    }
    .operation_box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .operation_box_img {
        position: relative;
        width: 120px;
        height: 120px;
        margin-bottom: 20px;
        cursor: pointer;
        border: 2px solid rgba(0,0,0,0);
        border-radius: 10px;
        overflow: hidden;
        .scene_title {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 20px;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
            text-align: center;
            line-height: 20px;
            font-size: 12px;
        }
    }
    .operation_box_img_ {
        border: 2px solid var(--color-primary);
    }
    

    .btn_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .operation_btn_ {
            margin-top: 0;
            margin-bottom: 20px;
            border-radius: 12px;
        }
        .operation_btn {
            width: 48%;
        }
    }
    .small_title {
        color: #1D1B1B;
    }
}

.lightCanvas {
    position: relative;
    .paint, .paintMouse {
        position: absolute;
        z-index: 1;
    }
    .paintMouse {
        z-index: 2;
    }
}