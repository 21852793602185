.process-img-box{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .process-img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 8px;
    }
}

.process-contain{
    position: absolute;
    width: 70%;
    height: 4px;
    border-radius: 10px;
    background: rgba(#000000, .4);
    .process-bar{
        width: 0;
        height: 100%;
        background-color: var(--color-primary);
        border-radius: 10px;
        transition: width .2s linear;
    }
}

.completion-icon, .failed-icon{
    position: absolute;
    width: 60%;
    height: 60%;
    object-fit: contain;
}
.failed-icon{
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .8);
}