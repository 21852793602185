.Drag {
    position: relative;
    background: url('~@assets/images/Musk.png') repeat;
    border-radius: 8px;
    // border: 2px dashed var(--color-primary);
    overflow: hidden;
    .dragBtnBox {
        position: absolute;
        background-color: #ffff;
        border-radius: 10px;
        z-index: 1;
        visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
        .menuItem {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;            
            border-radius: 4px;
            width: 42px;
            height: 42px;
            cursor: pointer;
            .imgContent {
                width: 22px;
                height: 22px;
                margin: 0 auto;
            }
            span {
                white-space: nowrap;
                display: inline-block;
                font-size: 12px;
                line-height: 1;
                text-align: center;
            }
            &:hover {
                background-color: #EBF0FF;
            }
        }
        .menuItem1 {
            .imgContent {
                background: url("~@assets/images/icon_upper class_normal@2x.png") center / cover no-repeat;
            }
        }
        .menuItem2 {
            .imgContent {
                background: url("~@assets/images/icon_next level_normal@2x.png") center / cover no-repeat;
            }
        }
        .menuItem3 {
            .imgContent {
                background: url("~@assets/images/icon_delete_normal@2x.png") center / cover no-repeat;
            }
        }
        .menuItem4 {
            .imgContent {
                background: url("~@assets/images/icon_clone_normal@2x.png") center / cover no-repeat;
            }
        }
        .menuItem5 {
            .imgContent {
                background: url("~@assets/images/icon_flips_normal@2x.png") center / cover no-repeat;
            }
        }
        .menuItem6 {
            .imgContent {
                background: url("~@assets/images/icon_vertical_normal@2x.png") center / cover no-repeat;
            }
        }
    }
}