.selectModal_search_list{
    padding: 10px 20px;
}

.selectModal_body{
    padding: 10px 3px;
    height: 568px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: .05rem;
    }
    &::-webkit-scrollbar-track {
        background-color: #e8e9ee;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #FFF;
        border-radius: 10px;
    }
    .empty_wrap{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .model_divider{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        height: 36px;
        i{
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-right: 12px;
        }
    }
    .select_item{
        position: relative;
        cursor: pointer;
        margin: 10px 17px;
        display: inline-block;
        width: calc(100%/7 - 34px);
        height: auto;
        &::after{
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
        }
        &.tryOn::after{
            // padding-bottom: 133.14%;
            padding-bottom: 166.86%;
        }
        border-radius: 10px;
        overflow: hidden;
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .private_icon{
            display: block;
            width: 26px;
            height: 12px;
            background: url('~@assets/images/icon_private.svg') left top / cover no-repeat;
            position: absolute;
            left: 0;
            top: 0;
        }
        .new_icon{
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
            height: 16px;
            text-align: center;
            font-size: 12px;
            line-height: 16px;
            color: #FFFFFF;
            background: linear-gradient(104.07deg, rgba(104, 107, 253, 0.7) 19.55%, rgba(211, 197, 255, 0.7) 89.24%);
            border-bottom-right-radius: 12px;
            span{
                display: inline-block;
                transform: scale3d(.7, .7, 1);
            }
        }
        p{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 20px;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
            text-align: center;
            line-height: 20px;
            font-size: 12px;
        }
        &.selected_item{
            border: 2px solid var(--color-primary);
        }
    }
}

.more_scene_handler{
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: 30.5%;
    border-bottom-left-radius: .08rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    .more_scene_handler_img{
        display: block;
        position: absolute;
        width: 63.33%!important;
        height: 63.33%!important;
        background: url('~@assets/images/icon_gallery2.svg') center / cover no-repeat;
    }
    &::after{
        content: "";
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }
}