.customized {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .customized_title {
        width: 80%;
        min-width: 80%;
    }
    .main_box {
        display: flex;
        width: 100%;
        justify-content: space-between;
        &.column{
            flex-direction: column;
            >div {
                width: 100%;
                margin: 0 auto;
                &:first-child{
                    margin-bottom: 20px;
                }
            }
        }
        >div {
            width: calc((100% - 20px)/ 2);
            border-radius: 12px;
            padding: .3rem; 
        }
        .div1 {
            position: relative;
            background: linear-gradient(180deg, #D0F0FF 0%, #E5F7FF 100%);
            display: flex;
            .div1_left {
                position: absolute;
                left: .3rem;
                top: .3rem;
                .title {
                    font-size: .28rem;
                    font-weight: bold;
                }
                .PlanItemContent_ul {
                    li {
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        color: #515275;
                        font-size: 14px;
                        line-height: 1.5;
                        margin-top: .1rem;
                        padding-left: .23rem;
                        img {
                            position: absolute;
                            left: 0;
                            top: .75em;
                            transform: translate(0, -50%);
                            width: .18rem;
                        }
                    }
                }
            }
            .div1_right {
                flex: 1;
                padding-top: .2rem;
                .div1_right_title {
                    text-align: right;
                    span {
                        font-size: .28rem;
                        font-weight: bold ;
                    }
                }
                .tip {
                    font-size: 10px;
                    text-align: right;
                    color: #515275;
                }
                .btn_box {
                    display: flex;
                    float: right;
                    margin-top: .6rem;
                    .btn_box_left {
                        background-color: #fff;
                        border-radius: 8px 0 0 8px;
                        .create_styl {
                            position: relative;
                            height: 18px;
                            margin: 10px 15px;
                            font-size: 14px;
                            line-height: 18px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            .use_point {
                                display: flex;
                                align-items: baseline;
                                img {
                                    width: 12px;
                                    height: 12px;
                                    margin-right: 3px;
                                }
                            }
                            .num_btn {
                                text-align: center;
                                background-color: #f3f3f3;
                                user-select: none;
                                width: 14px;
                                height: 14px;
                                line-height: 14px;
                                text-align: center;
                                border-radius: 2px;
                                cursor: pointer;
                                margin: 0 3px;
                                opacity: 1;
                                &:hover {
                                    opacity: 0.8;
                                }
                            }
                        }

                    }
                    .btn_box_right {
                        border-radius: 0 8px 8px 0 ;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width:  100px;
                        height: auto;
                        background-color: #64A6FF;
                        text-align: center;
                        color: #fff;
                        cursor: pointer;
                    }
                }
                    
                
            }
        }
        .div2 {
            background: linear-gradient(226.1deg, #34364A -34.12%, rgba(42, 46, 109, 0.667778) 106.62%);
            display: flex;
            .div2_left {
                width: calc(100% - 180px);
                .title {
                    font-size: .28rem;
                    font-weight: bold;
                    color: #fff;
                }
                .PlanItemContent_ul {
                    li {
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        color: #fff;
                        font-size: 14px;
                        line-height: 1.5;
                        margin-top: .1rem;
                        padding-left: .23rem;
                        img {
                            position: absolute;
                            left: 0;
                            top: .75em;
                            transform: translate(0, -50%);
                            width: .18rem;
                        }
                    }
                }
            }
            .div2_right {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 180px;
                img {
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }
}