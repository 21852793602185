.DragAndScaleWrap{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    .ImgSize{
        margin-top: 20px;
        color: #4f4f4f;
        font-size: 12px;
    }
    .Shortcut{
        color: #4f4f4f;
        font-size: 12px;
        margin-top: 52px;
        width: 100%;
        li{
            width: 100%;
            display: flex;
            justify-content: space-between;
            &:first-child{
                font-weight: bold;
                margin-bottom: 16px;
            }
            &:not(:first-child):not(:last-child){
                margin-bottom: 8px;
            }
            i.Icon{
                display: inline-block;
                width: 74px;
                height: 20px;
                background: center / cover no-repeat;              
                &.MouseWheel{
                    background-image: url('~@assets/images/icon_mouse_wheel.svg');
                }
                &.MouseRight{
                    background-image: url('~@assets/images/icon_mouse_right.svg');
                }
                &.SpaceKeyAndLeft{
                    background-image: url('~@assets/images/icon_space_key_mouse_left.svg');
                }
                &.CtrlAndZ{
                    background-image: url('~@assets/images/icon_ctrl_key_z.svg');
                }
                &.DeleteKey{
                    background-image: url('~@assets/images/icon_delete_key.svg');
                }
            }
        }
    }
}