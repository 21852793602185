.Container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Select{
    width: 110px;
    margin-right: 20px;
    :global(.ant-select-selection-item){
        text-align: center;
    }
    // @media screen and (max-width: 1020px) {
    //     margin-right: 10px;
    // }
}
.SelectPopup{
    :global(.ant-select-item.ant-select-item-option){
        text-align: center;
    }
}

.InputContainer{
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.InputContent{
    flex: none;
    width: 76px;
    display: flex;
    align-items: center;
    // @media screen and (max-width: 1020px) {
    //     width: 66px;
    // }
}

.Input{
    width: 53px;
    padding: 0;
    border-radius: 0;
    border-bottom: 1px solid #e6e8f2!important;
    margin-right: 10px;
    text-align: center;
    // @media screen and (max-width: 1020px) {
    //     width: 40px;
    // }
}
.LockBtn{
    cursor: pointer;
    width: 16px;
    height: 16px; 
    background: url('~@assets/images/icon_locked.svg') center / cover no-repeat;
    &.unlock {
        background-image: url('~@assets/images/icon_unlock.svg');
    }
    &.disabled{
        filter: invert(100%);
    }
}

.InputSuffix{
    color: #6d6d6d;
    font-size: 12px;
}

.RatioOnly{
    display: flex;
    justify-content: center;
    align-items: center;
    >div{
        cursor: pointer;
        width: 64px;
        height: 26px;
        border-radius: 8px;
        text-align: center;
        font-size: 12px;
        line-height: 26px;
        border: 1px solid #EFF2FD;
        &:not(:last-child){
            margin-right: 12px;
        }
        &.actived{
            border-color: var(--color-primary);
        }
    }
}