.MagicErase {
    .title {
        width: 100%;
        text-align: left;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .magicEraseBar_btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        cursor: pointer;
        .magicEraseBar_btn_ {
            cursor: pointer;
            display: flex;
            width: calc((100% - 20px) / 3);
            padding: 5px 0;
            flex-wrap: wrap;
            justify-content: center;
            background: rgba(235, 240, 255, 1);
            border-radius: 8px;
            border: 1px solid rgba(235, 240, 255, 1);
            img {
                width: 24px;
                height: 24px;
            }
            p {
                width: 100%;
                margin-top: 3px;
                text-align: center;
            }
        }
        .magicEraseBar_btn_selected {
            background-color: #fff;
            border: 1px solid rgba(117, 120, 254, 0.2);
            p {
                color: #393EFD;
                font-weight: 700;
            }
        }
    }
    .operation_btn {
        margin-top: 30px;
        border-radius: 12px;
    }
    .edit_center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .shift_tip {
        align-items: flex-start;
        width: 100%;
        margin-left: 0;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .operation_box {
        i{
            display: inline-block;
            width: 23px;
            height: 19px;
            cursor: pointer;
            background: center / contain no-repeat;
        }
        display: flex;
        width: 100%;
        .ant-slider {
            width: 100%;
            margin: 4px 10px 0 10px;
        }
        .size {
            font-size: 12px;
        }
    }

    .scaleBoxPaint {
        width: 120px;
        .unit {
            background-color: rgba(255, 255, 255, 1);
            height: 32px;
            padding-right: 5px;
            line-height: 32px;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
        }
        .zoom_Value {
            padding: 0 5px;
            height: 32px;
            line-height: 32px;
            background-color: rgba(255, 255, 255, 1);
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
        }
        .ant-btn-default {
            background: rgba(255, 255, 255, 1);
            border-color: #fff;
        }
        .ant-input {
            border-right: 0;
            padding-right: 2px;
        }
        .ant-btn {
            padding: 4px 6px;
        }
        .ant-input:focus, .ant-input-focused, .ant-input:hover {
            border-color: #fff;
            box-shadow: none
        }
    }

    .edit_right_left {
        width: 2rem;
    }

    .result_img {
        max-width: 600px;
        max-height: 600px;
        border-radius: 10px;
    }
    .ImgComparer {
        border-radius: 10px;
    }
}

.magicErase_canvasBox{
    position: relative;
    .magicErase_canvas{
        position: absolute;
        left: 0;
        top: 0;
    }
    .magicErase_bgcanvas{
        position: relative;
    }
}