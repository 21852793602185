.Container{
    width: 100%;
    .Head{
        font-size: 24px;
        line-height: 40px;
        height: 40px;
        font-weight: 500;
        text-align: center;
        margin: 24px 0 16px;
    }
    .Content{
        width: 460px;
        margin: 0 auto 24px;
    }
}

.PowerItem{
    cursor: pointer;
    position: relative;
    width: 100%;
    border-radius: 16px; 
    border: 4px solid rgba(209, 228, 255, 1);
    background: rgba(255, 255, 255, 1);
    &::after{
        content: "";
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 21.7%;
    }
    &.actived{
        border-color: rgba(108, 131, 255, 1);
        background: linear-gradient(94.6deg, #DBE0FF 1.11%, #D7EAFB 109.45%) no-repeat;
        .powerAmount{
            background: rgba(108, 131, 255, 1);
        }
    }
    &:not(:last-child){
        margin-bottom: 12px;
    }
    .powerAmount{
        position: absolute;
        top: 20px;
        right: 24px;
        padding: 0 12px;
        height: 28px;
        border-radius: 16px;
        background: rgba(49, 66, 82, 1);
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 1;
        &::after{
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            background: url('~@assets/images/arcbean.png') center / cover no-repeat;
            margin-left: 8px;
        }
    }
    .tips{
        position: absolute;
        left: 24px;
        bottom: 14px;
        font-size: 14px;
        line-height: 21px;
        color: #47637C;
    }
    .priceBox{
        position: absolute;
        left: 24px;
        bottom: 40px;
        font-weight: 700;
        >span:first-child{
            font-size: 20px;
        }
        >span:nth-child(2){
            font-size: 28px;
        }
        .CouponPrice{
            position: relative;
            display: inline-flex;
            justify-content: flex-start;
            align-items: flex-end;
            color:#979797;
            font-size: 14px;
            height: 16px;
            line-height: 1;
            margin-left: 16px;
            &::before{
                content: "";
                display: block;
                width: 16px;
                height: 16px;
                background: url('~@assets/images/icon_rebate.svg') center / cover no-repeat;
                margin-right: 8px;
            }
        }
    }
}

.CouponSelectWrap{
    width: 100%;
    height: 32px;
    margin: 0 auto;
    margin-top: 12px;
    .CouponSelect{
        width: 100%;
        height: 100%;
        .CouponSelected{
            font-size: 16px;
            line-height: 32px;
            &:not(.none){
                color: #ff7532;
            }
        }
    }
}

.PayChannel{
    margin: 12px 0 20px;
    padding: 0 28px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >span{
        margin-right: 28px;
    }
    .PayChannelRadio{
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        >span.ant-radio{
            margin-right: 16px;
        }
        >span:not(.ant-radio){
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            .icon{
                width: 24px;
                object-fit: cover;
                margin-right: 8px;
            }
        }        
    }
    :global(.ant-radio-group) {
        flex: 1;
        display: flex;
        justify-content: space-between;
    }
}

.PayBtn{
    height: 40px;
    font-size: 16px;
}

.Tips{
    width: 100%;
    color:#979797;
    font-size: 12px;
    line-height: 20px;
    margin: 0 auto;
    text-align: left;
    margin-top: 20px;
    li{
        position: relative;
        padding-left: 1em;
        &::before{
            content: '*';
            position: absolute;
            left: 0;
            top: 2px;
            margin-right: 5px;
        }
        a{
            text-decoration: underline;
            margin-left: 4px;
        }
    }
    span{
        color: var(--color-primary);
    }
}