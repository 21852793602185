.Container{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UploadBox{
    width: 83.5%;
    max-width: 800px;
    height: 353px;
    // max-height: 886px;
    display: flex;
    flex-direction: column;
    :global(.ant-spin-nested-loading), :global(.ant-spin-container){
        height: 100%;
    }
}
.Upload{
    height: 100%;
    &:global(.ant-upload-wrapper.ant-upload-picture-card-wrapper){
        :global(.ant-upload.ant-upload-select){
            width: 100%;
            height: 100%;
            margin: 0;
            background-color: transparent;
            border-color: #d9d9d9;
            border-radius: 12px;
        }
    }
}
.UploadContent{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction:column;
    justify-content: flex-end;
    align-items: center;
}

.UploadHandle{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .UploadBtn{
        width: 228px;
        height: 44px;
        font-size: 20px;
        line-height: 44px;
        margin-bottom: 16px;
    }
    .UploadTip{
        font-size: 16px;
        height: 18px;
        line-height: 18px;
        margin-bottom: 8px;
        color: #262626;
    }
    .UploadTip2{
        font-size: 12px;
        height: 18px;
        line-height: 18px;
        margin-bottom: 12px;
        color: #979797;
    }
    .UploadSample{
        >p{
            white-space: nowrap;
            font-size: 12px;
            height: 18px;
            line-height: 18px;
            margin-bottom: 14px;
            color: rgba(0, 0, 0, .6);
            display: flex;
            justify-content: center;
            align-items: center;
            &::before, &::after{
                content: "";
                display: block;
                width: 120px;
                height: 1px;
                background-color: #CBCBCB;
            }
            &::before{
                margin-right: 20px;
            }
            &::after{
                margin-left: 20px;
            }
        }
        > ul{
            display: flex;
            justify-content: center;
            margin-bottom: 40px;
            li{
                cursor: pointer;
                width: 52px;
                height: 70px;
                border-radius: 10px;
                overflow: hidden;
                img{ width: 100%; }
                &:not(:last-child){
                    margin-right: 10px;
                }
            }
        }
    }
}