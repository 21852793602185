.StrengthSlider{
    user-select: none;
    width: 100%;
    > p{
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 19px;
        span:first-child{
            display: inline-block;
            font-size: 12px;
            height: 19px;
            line-height: 19px;
            margin-right: 10px;
        }
        i{
            display: block;
            width: 15px;
            height: 14px;
            background: url('~@assets/images/icon_help.svg') center / cover no-repeat;
        }
    }
    :global(.ant-slider){
        width: calc(100% - 14px);
        margin: 10px auto 0;
    }
    :global(.ant-slider .ant-slider-step) {
        height: 8px;
        :global(.ant-slider-dot){
            width: 14px;
            height: 14px;
            top: 50%;
            transform: translate(-50%, -50%)!important;
        }
    }
    :global(.ant-slider .ant-slider-track) {
        background-color: #4E63FF;
        height: 8px;
        border-radius: 10px;
    }
    :global(.ant-segmented.ant-segmented-block) {
        padding: 10px 5px;
    }
    :global(.ant-segmented .ant-segmented-item) {
        border-radius: 8px;
    }
    :global(.ant-slider-horizontal .ant-slider-rail) {
        height: 8px;
        border-radius: 10px;
    }
    :global(.ant-slider-horizontal .ant-slider-handle) {
        inset-block-start: 3px
    }
}
