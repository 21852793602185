// 主色调
$main-bg-color: #fff;
$main-primary-color: #326fff;
$main-default-color: #4f4f4f;

.home_appeal {
    p:nth-child(2) {
        margin-top: 20px;
        color: #6F6F6F;
        span {
            color: var(--color-primary);
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.Home  {
    color: $main-default-color;
    height: calc(100% - 60px);
    overflow: hidden;
    color:#1d1b1b;
    position: relative;
    


    .preImg {
        max-width: 100%; 
        max-height: 100%;
    }
     // 走马灯
    .ant-carousel .slick-dots-bottom {
        bottom: -18px;
    }
    .ant-carousel .slick-dots li button {
        height: 4px;
        border-radius: 16px;
    }
    // 弹框
    // .ant-modal {
    //     width: 1220px!important;
    // }

    // 页码
    .ant-pagination .ant-pagination-item {
        min-width: 24px;
        height: 24px;
        line-height: 24px;
    }
    .model_box {
        overflow: auto;
        padding: 10px 0 20px 0;
        .model_box_sam {
            position: relative;
            border-radius: 10px 0 0 10px;
            overflow: hidden;
            width: 512px;
            height: 512px;
            float: left;
            // 涂抹画板
            .paint, .paintBg, .paintMove, .paintMouse, .interactSegment, .interactSegment_bg, .interactSegment_mask {
                position: absolute;
                top: 0;
                left: 0;
                width: 512px;
                height: 512px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 100;
                overflow: hidden;
                border-radius: 0 0  0 10px;
            }
            .paintBg, .interactSegment_bg {
                z-index: 90;
            }
            .paintMouse, .interactSegment_mask {
                z-index: 91;
            }
        }
        .model_box_view {
            float: left;
            width: 512px;
            height: 512px;
            // background: url('~@assets/images/Musk.png') repeat;
            border-radius: 0 10px 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-height: 512px;
                max-width: 512px;
                background: url('~@assets/images/Musk.png') repeat;
            }
        }
        .model_box_right {
            float: left;
            width: calc(100% - 1024px);
            .p_ {
                font-size: 12px;
                color:#4f4f4f;
                margin-bottom: 1px;
                padding-left: 5px;
            }
            .model_box_right_bth {
                display: flex;
                background-color: #fff;
                border-radius: 4px;
                padding: 10px 10px;
                max-width: 110px;
                width: 100%;
                cursor: pointer;
                margin-bottom: 5px;
                border: 1px solid #fff;
                justify-content: center;
                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                    margin-top: 1px;
                }
            }
            .model_ban {
                background-color: #dedede;
                cursor: not-allowed;
            }
            .samll_btn {
                padding: 4px 10px;
                display: flex;
                font-size: 12px;
                img {
                    height: 15px;
                    width: 15px;
                    margin-top: 3px;
                }
            }
            .model_box_right_bth_ {
                border: 1px solid var(--color-primary);
                color: var(--color-primary);
            }
        }
    }
    .modal_top {
        padding-left: calc(100% - 1030px);
        .top_item {
            display: inline-block;
            background-color: #fff;
            border-radius: 10px;
            .small_item {
                float: left;
                padding: 7px 15px;
                font-size: 12px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                cursor: pointer;
                img {
                    height: 15px;
                    width: 15px;
                }
                p {
                    width: 100%;
                    text-align: center;
                    margin-top: 5px;
                }
            }
        }
        .top_slider {
            display: inline-block;
            width: 150px;
            float: left;
            padding: 5px 15px 0 0;
            p {
                overflow: auto;
                font-size: 12px;
                margin-bottom: 5px;
                span:nth-child(1) {
                    float: left;
                }
                span:nth-child(2) {
                    float: right;
                }
            }
            .ant-slider {
                margin: 0;
            }
        }
        .top_item_2 {
            margin-left: 30px;
            position: relative;
            .top_item_2_shade {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(251, 251, 251, .6);
                border-radius: 10px;
            }
        }
    }
    .ant-modal-close {
        display: none;
    }
}

.Home_main {
    height: 100%;
    padding: 8px;
    width: 100%;
    display: flex;
    background-color: #F5F6FC;
    .main_style {
        background: #fff;
        height: 100%;
        box-shadow:2px 2px 20px rgba(221, 231, 255, 0.5);
        border-radius: 12px;
        overflow-y: auto;
        // padding-bottom: 10px;
        &::-webkit-scrollbar {
            width: .05rem;
        }
        &::-webkit-scrollbar-track {
            background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #e8e9ee;
            border-radius: 10px;
        }
    }
    .main_right {
        flex: 1;
        // width: calc(100% - 90px);
    }
    .ant-carousel .slick-dots li.slick-active button,
    .ant-carousel .slick-dots li button { //跑马灯切换按钮
        background: #9a9999;
    }
}
// sam modal 背景
.sam_modal {
    .ant-modal-content {
        background-color: #f8f9ff!important;
        margin-bottom: 0;
        min-width: 1140px;
    }
    .ant-modal-header{
        background-color: #f8f9ff!important;
        margin-bottom: 0;
    }
    @media screen and (max-width: 1200px) {
        .ant-modal-content{
            padding: 10px 10px;
        }
    }
}

// 下拉菜单
.ant-space {
    cursor: pointer;
}
.ant-dropdown:not(.no-custom) .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
    background: rgba(0,0,0, .6);
    .ant-dropdown-menu-item {
        color: #fff;
        font-size: 12px;
    }
}

.ant-dropdown:not(.no-custom) .ant-dropdown-menu .ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-divider,
.ant-dropdown:not(.no-custom) .ant-dropdown-menu .ant-dropdown-menu-submenu-title-divider,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-divider {
    background: #4f4f4f;
}

.ant-dropdown:not(.no-custom) .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown:not(.no-custom) .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown:not(.no-custom) .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown:not(.no-custom) .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active {
    background-color: rgba(0, 0, 0, 0.1)
}

// 个人中心提示框
.popover_box {
    border-radius: 8px;
    overflow: hidden;
}
.person_popover {
    .ant-popover-inner {
        padding: 0;
    }
}

.crop {
    .ord-e, .ord-n, .ord-w, .ord-s{
        display: none;
    }
}

.custom-alert-message{
    .ant-message-notice-content{
        padding: 0!important;
        background: transparent!important;
    }
}

.ant-select-dropdown {
    min-width: 60px!important;
}

// 去掉自动填充的背景色
input:-webkit-autofill{
    transition: background-color 50000s ease-in-out 0s;
}
input:-internal-autofill-selected {
    background-color: transparent!important;
}