.mobileModal_body{
    padding-top: 20px;
    .mobileModal_subtitle{
        width: 80%;
        margin: 0 auto 20px;
        font-size: 14px;
        color: #999;
    }
    .mobileModal_form{
        width: 80%;
        margin: 0 auto;
    }
    .mobileModal_code{
        margin: 20px 0 0;
        display: flex;
        :global(.ant-input-affix-wrapper) {
            width: 200px;
        }
        :global(.ant-btn) {
            width: 74px;
            font-size: 14px;
            margin-left: 15px;
        }
        .mobileModal_code_label{
            margin-right: 20px;
            height: 40px;
            line-height: 40px;
        }
    }
    .mobileModal_mobile{
        margin: 20px 0;
        display: flex;
        :global(.ant-input-affix-wrapper) {
            width: 200px;
            &.mobileModal_new_input{
                width: 289px;
            }
        }
        :global(.ant-btn) {
            width: 74px;
            font-size: 14px;
            margin-left: 15px;
        }
        .mobileModal_new_label{
            margin-right: 20px;
            height: 40px;
            line-height: 40px;
        }   
    }
    .err_text {
        width: 100%;
        margin-top: 10px;
        color: #d51616;
        margin-left: 90px;
    }
}