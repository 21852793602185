.Container{
    padding: 0 8px;
    .Content{
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 13px;
        .SelectionItemBox{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
        }
    }
    .empty_wrap{
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }    
}
