.Container {
    width: 100%;
    :global(.ant-row) {
        margin-bottom: 5px;
    }
    textarea {
        margin-bottom: 5px;
    }
}

.Prompt{
    padding: 0 4px;
    margin-bottom: 24px;
    .PromptInput{
        padding: 8px;
        background: rgba(239, 242, 253, 1);
    }
}

.PromptTitle, .MaterialTtile{
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
    padding: 0 4px;
}


.MaterialTools{
    padding: 0 4px;
    display: flex;
}

.MaterialUploader{
    margin-right: 30px;
    width: auto!important;
    :global(.ant-upload.ant-upload-select){
        width: 100px!important;
        height: 100px!important;
        border-radius: 8px;
        margin: 0!important;
        // @media screen and (max-width: 1020px){
        //     width: 80px!important;
        //     height: 80px!important;
        // }
    }
    &:hover{
        .MaterialUploaderBox{
            color: var(--color-primary);
        }        
    }
    .MaterialUploaderBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        color:#979797;
    }
}

.MaterialStrength{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    >p{
        font-size: 12px;
        line-height: 12px;
        font-weight: 700;
    }
}


.MaterialList{
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    :global(.ant-carousel .slick-dots li.slick-active button) {
        background-color: #959494;
    }
    :global(.ant-carousel .slick-dots-bottom) {
        bottom: -8px;
    }
    :global(.ant-carousel .slick-dots li button) {
        background-color: #4b4b4b;
    }
    .MaterialGroup{
        padding: 0 2px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: calc((100% - 300px) / 2);
        // @media screen and (max-width: 1020px){
        //     gap: calc((100% - 240px) / 2);
        // }
    }
    :global(.ant-carousel) {
        width: 100%;
        height: 100%;
        .slick-slider{
            height: 100%;
            .slick-list{
                height: 100%;
            }
        }
    }

    .pro_input {
        margin-top: 40px;
    }
}

.materialBox{
    position: relative;
    margin-top: 12px;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background-color: rgba(10,10,10,.2);
    border: 2px solid #fff;
    cursor: pointer;
    overflow: hidden;
    // @media screen and (max-width: 1020px){
    //     width: 80px;
    //     height: 80px;
    // }
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    &.selectedImg {
        border: 2px solid var(--color-primary);
        overflow: hidden;
    }
    &:hover{
        .del_btn{
            visibility: visible;
        }
    }
}

.del_btn {
    position: absolute;
    right: 5px;
    top: 5px;
    display: block;
    width: 20px;
    height: 20px;
    z-index: 10;
    visibility: hidden;
    background: url('~@assets/images/Remove_Normal.svg') center / cover no-repeat;
    transition: background-image .2s;
    &:hover{
        background-image: url('~@assets/images/Remove_hover.svg')
    }
    &:active{
        background-image: url('~@assets/images/Remove_Press.svg')
    }
}