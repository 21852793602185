.Container{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    background-color: #FFFFFF;
    padding: 16px;
    display: flex;
    flex-direction: column;
    >p{
        font-size: 12px;
        line-height: 16px;
        font-weight: bold;
        margin-bottom: 46px;
    }
    .Resolution{
        cursor: pointer;
        margin-bottom: 24px;
        height: 40px;
        font-size: 16px;
        line-height: 40px;
        font-weight: 500;
        color: #8D8D8D;
        text-align: center;
        background-color: #EFF2FD;
        border-radius: 12px;
        &.selected{
            color: #FFFFFF;
            background-color: var(--color-primary);
        }
    }
    .GenerateBox{
        width: calc(100% - 32px);
        position: absolute;
        bottom: 16px;
        .use_point{
            display: flex;
            justify-content: flex-end;
            align-items: baseline;
            margin-bottom: 12px;
            img {
                margin-left: 12px;
                height: 10px;
                width: 10px;
            }
        }
        >button{
            width: 100%;
            height: 44px;
            font-size: 20px;
        }
    }
}