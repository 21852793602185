.Placeholder{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    // background-color: rgba(0,0,0,.1);
    background:#efefef;
    border-radius: .2rem;
    overflow: hidden;
    img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .WatiingRender{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p{
            white-space: nowrap;
            width: 100%;
            color: #4f4f4f;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            text-align: center;
            span{
                font-size: 18;
                font-weight: 700;
            }
            span:first-child{
                margin-left: 4px;
            }
        }
    }
    .Mask{
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(29, 27, 27, .8);
    }
}