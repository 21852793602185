:global(.ant-spin-dot-spin) {
    &.psai_spin_dot{
        // animation-name: none!important;
        // transform: rotate(0)!important;
        animation-duration: 2s;
        // width: 24px;
        // height: 24px;
        position: relative;
        :global(.ant-spin-dot-item){
            width: .25em;
            height: .25em;
            // background-color: #FFFFFF;
            position: absolute;
            left: 50%;
            top: 0;
            transform-origin: 50% .5em;
            &:nth-child(1){
                transform: translate(-50%, 0) rotate(0deg); // 0s
                animation-delay: 0s;
            }
            &:nth-child(2){
                transform: translate(-50%, 0) rotate(60deg); // 0.4s
                animation-delay: 0.4s;
            }
            &:nth-child(3){
                transform: translate(-50%, 0) rotate(120deg); // 0.8s
                animation-delay: 0.8s
            }
            &:nth-child(4){
                transform: translate(-50%, 0) rotate(180deg); // 1.2s
                animation-delay: 1.2s
            }
            &:nth-child(5){
                transform: translate(-50%, 0) rotate(240deg);
                animation-delay: 1.6s;
            }
            &:nth-child(6){
                transform: translate(-50%, 0) rotate(300deg);
                animation-delay: 2.0s;
            }
        }        
    }
}
