.blurBgCont {

    .edit_center_ {
        .ant-radio-wrapper {
            margin-right: 40px;
        }
    }
    .edit_center_ .rcslider:last-child {
        margin-bottom: 0;
    }
    .edit_right_c {
        display: flex;
        align-items: center;
    }
    .operation_btn {
        width: 48%;
        margin-top: 20px;
    }
}