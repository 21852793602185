html, body, #root, .App {
    height: 100%;
    background-color: #f8f9ff;
    font-size: 12px;
}

html{
    --color: 238, 98%;
    --color-45: hsla(var(--color), 45%, 1);
    --color-55: hsla(var(--color), 55%, 1);
    --color-61: hsla(var(--color), 61%, 1);
    --color-65: hsla(var(--color), 65%, 1);
    --color-75: hsla(var(--color), 75%, 1);
    --color-85: hsla(var(--color), 85%, 1);
    --color-95: hsla(var(--color), 95%, 1);
    --color-98: hsla(var(--color), 98%, 1);
    --color-primary: var(--color-61); // #185dff; // hsla(222, 100%, 55%, 1)
    --color-primary-darker: var(--color-45);
    --color-primary-lighter: var(--color-65);
    --base-width: 1920;
    --min-font-size: 50px;
    --max-font-size: 999px;
    min-width: calc(var(--min-font-size) * 19.2);
    min-height: calc(var(--min-font-size) * 15);
    overflow: auto;
    &::-webkit-scrollbar {
        width: .05rem;
        height: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #e8e9ee;
        border-radius: 10px;
    }
}

body {

  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin: 0;
}
._beauty_scrollbar{
  overflow: auto auto;
  &::-webkit-scrollbar {
    width: .05rem;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
      background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
      background-color: #e8e9ee;
      border-radius: 10px;
  }
}

._feature{
    display: inline-flex;
    align-items: center;
    &:not(:last-child):after{
        content: "/";
        margin: 0 4px;
        display: inline-block;
    }
}

.registered_trademark{
  font-size: .5em;
  vertical-align: super;
}

.browser_not_available{
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 90%;
    margin: 0 auto;
    font-size: 20px;
    line-height: 2;
    text-align: center;
    i{
        display: block;
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: 80px;
        &::after{
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 11.65%;
        }
        background: url("~@assets/images/01_logo.svg") center / cover no-repeat;
        background-position: center 44%;
    }
}
