.LoggedHead{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .LoggedHead_power{
        height: 24px;
        margin-top: 4px;
        // background: linear-gradient(180.03deg,#edf0ff 0%,#f8f9ff 0%,#edf0ff 100%);
        border-radius: 8px;
        margin-right: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px 0 0;
        // background: url('~@assets/images/backgrounds_favorable.png') center / cover no-repeat;
        background: linear-gradient(123.91deg, #B68FFF 17.86%, #393EFD 92.46%);
        &::before{
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            margin: 0 4px 0 8px;
            background: url('~@assets/images/arcbean.png') center / cover no-repeat;
        }
        .LoggedHead_powerAmount{
            display: inline-block;
            color: #fff;
            font-size: 14px;
            height: 27px;
            text-align: center;
            cursor: pointer;
            >span{
                display: inline-block;
                font-size: 14px;
                height: 24px;
                line-height: 24px;
            }
        }
        .LoggedHead_label{
            color: #1d1b1b;
            font-size: 12px;
        }
        .LoggedHead_label{
            color: #1d1b1b;
            font-size: 12px;
        }
        .LoggedHead_recharge{      
            width: 48px;
            height: 16px;
            background: url('~@assets/images/frame01.png') center / cover no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: -1px;
            > span{
                display: inline-block;
                transform: translateX(4px);
                color:#fff;
                font-size: 12px;
                width: 60%;
                height: 16px;
                line-height: 16px;
                text-align: center;
                cursor: pointer;
                transition: color .2s;
                &:hover{
                    color: var(--color-primary);
                }
            }
        }
    }
    .LoggedHead_photo{
        height: 32px;
        width: 32px;
        cursor: pointer;
    }
}

.popover_box_ {
    padding: 10px 15px;
    .popover_box_top {
        width: 160px;
        display: flex;
        margin-bottom: 10px;
        .img1 {
            height: 36px;
            width: 36px;
            margin-right: 20px;
        }
        .img2 {
            height: 10px;
            width: 16px;
            margin-right: 10px;
            margin-top: 6px;
        }

        ul {
            width: calc(100% - 36px - 10px);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            overflow: hidden;
        }
        .li1, .li2 {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            // .vip_icon{
            //     display: inline-block;
            //     width: 16px;
            //     height: 10px;
            //     margin-right: 10px;
            //     background: center / cover no-repeat;
            //     &:global(.free){ background-image: url('~@assets/images/VIP_Free.svg'); }
            //     &:global(.basic){ background-image: url('~@assets/images/VIP_Basic.svg'); }
            //     &:global(.professional){ background-image: url('~@assets/images/VIP_Professional.svg'); }
            //     &:global(.ultimate){ background-image: url('~@assets/images/VIP_Ultimate.svg'); }
            // }
        }
    }
}

.balance_p {
    display: flex;
    justify-content: space-between;
    span {
        font-size: 12px;
    }
}
.coupon_p{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    height: 24px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 24px;
    background: linear-gradient(to right, rgba(255, 132, 73, 1), rgba(255, 87, 99, 1));
    text-align: center;
    cursor: pointer;
    margin-bottom: 8px;
    &::before{
        content: "";
        display: block;
        width: 17px;
        height: 13px;
        background: url(~@assets/images/coupon_before.svg) center / contain no-repeat;
        position: absolute;
        left: 9px;
    }
    &::after{
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background: url(~@assets/images/coupon_after.svg) center / contain no-repeat;
        position: absolute;
        right: 4px;
    }
}
.center_p{
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
}
.logout_p {
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: #efefef;
    color:#4f4f4f;
    font-size: 12px;
    cursor: pointer;
}