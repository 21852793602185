.Container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .Head{
        margin-top: 12px;
        padding: 0 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .Title{
            font-size: 12px;
            font-weight: bold;
        }
        .VideoFilter{
            :global(.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder) {
                color: #6d6d6d;
            }
            :global(.ant-col) > label{
                display: inline-block;
                height: 24px;
                line-height: 24px;
                margin-right: 10px;
                &:not(:first-child){
                    margin-left: 20px;
                }
            }
            :global(.ant-select-multiple.ant-select-sm){
                font-size: 12px;
                :global(.ant-select-selection-overflow){
                    flex-wrap: nowrap;
                }
                :global(.ant-select-selection-item){
                    margin-inline-end: 2px;
                }
            }
            :global(.ant-select-selection-overflow-item){
                &:not(:first-child){
                    margin-left: 2px;
                }
            }
            .SelectionTag{
                display: inline-flex;
                justify-content: center;
                align-items: center;
                padding: 0 4px;
                font-size: 12px;
                height: 100%;
                background-color: #F0F0F0;
            }
        }
    }
    .Content {
        margin-top: 12px;
        padding: 0 8px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        flex: 1;
        overflow-y: auto;
        .SelectionItemBox{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
        }
        &::-webkit-scrollbar {
            width: .05rem;
            height: 10px;
        }
        &::-webkit-scrollbar-track {
            background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #e8e9ee;
            border-radius: 10px;
        }
    }
    .empty_wrap{
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.sceneItem {
    width: calc((100% - 16px)/2);
    &::after{
        content: "";
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 177.78%;
    }
    margin-bottom: 16px;
    border-radius: 12px;
    border: 2px solid #fff;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &.video_select {
        border: 2px solid var(--color-primary);
    }
    .sceneItemImg {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
        background-color: #CCC;
    }
    .private_icon{
        display: block;
        width: 26px;
        height: 12px;
        background: url('~@assets/images/icon_private.svg') left top / cover no-repeat;
        position: absolute;
        left: 0;
        top: 0;
    }
    .del_btn{
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 5px;
        display: block;
        width: 20px;
        height: 20px;
        z-index: 10;
        visibility: hidden;
        background: url('~@assets/images/Remove_Normal.svg') center / cover no-repeat;
        transition: background-image .2s;
        &:hover{
            background-image: url('~@assets/images/Remove_hover.svg')
        }
        &:active{
            background-image: url('~@assets/images/Remove_Press.svg')
        }
    }
    &:hover{
        .del_btn{
            visibility: visible;
        }
    }
}
.uploadWrap{
    cursor: pointer;
    border: 1px solid #D3DBFF!important;
    &:hover{
        border: 1px solid var(--color-primary)!important;
    }
    :global(.ant-upload.ant-upload-select),
    :global(.ant-upload.ant-upload-drag){
        position: absolute;
        width: 100%;
        height: 100%;
        border: none;
    }
    .addTrigger{
        width: 100%;
        height: 100%;
        position: relative;
        .addTriggerContent{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            > span{
                font-size: 24px;
                color: var(--color-primary);
                margin-bottom: 10px;
            }
            > .Title{
                font-size: 12px;
                color: var(--color-primary);
                margin-bottom: 20px;
            }
            > .UploadProgress{
                position: absolute;
                bottom: 26px;
                font-size: 12px;
                color: var(--color-primary);
            }
            > .Requirement{
                position: absolute;
                bottom: 4px;
                font-size: 12px;
                color: #D3DBFF;
                &:hover{
                    color: var(--color-primary);
                }
            }
            .cornerMark{
                position: absolute;
                left: 0;
                top: 0;
                width: 50px;
                height: 16px;
                font-size: 12px;
                line-height: 16px;
                color: #FFFFFF;
                background: linear-gradient(95.16deg, #393EFD -7.54%, #8C29FE 93.45%);
                border-bottom-right-radius: 12px;
                span{
                    display: inline-block;
                    transform: scale3d(.7, .7, 1);
                }
            }
        }
    }
}

.UploadExample{
    padding: 40px 40px 20px;
    display: flex;
    > div:not(:last-child){
        margin-right: 40px;
    }
    .UploadExampleTitle{
        font-size: 16px;
        border-radius: 12px;
        font-weight: bold;
        margin-bottom: 35px;
    }
    .UploadExampleList{
        display: flex;
        .UploadExampleItem{
            max-width: 133px;
            width: 100px;
            &:not(:last-child){
                margin-right: 20px;
            }
            >div{
                position: relative;
                width: 100%;
                height: auto;
                &::after{
                    content: "";
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-bottom: 164.67%;
                }
                img:first-child{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
                img:last-child{
                    position: absolute;
                    right: 8%;
                    bottom: 5%;
                    width: 15%;
                }
            }
            
            span{
                margin-top: 10px;
                font-size: 12px;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                white-space: nowrap;
            }
        }
    }
}
.UploadRequire{
    padding: 20px 40px 40px;
    .UploadRequireTitle{
        font-size: 16px;
        border-radius: 12px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .UploadRequireList{
        font-size: 14px;
        line-height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}