.GoBack{
    cursor: pointer;
    height: 44px;
    font-size: 16px;
    margin-bottom: 18px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    &::before {
        content: "";
        display: block;
        width: 9px;
        height: 9px;
        border-top: 2px solid rgba(29, 27, 27, 1);
        border-left: 2px solid rgba(29, 27, 27, 1);
        transform: rotate(-45deg);
        margin-right: 20px;
    }
}