.modelScale {
    // border: 1px dashed #326fff;
    // border-radius: 10px;
    position: relative;
    .dragBtnBox {
        position: absolute;
        display: none;
        overflow: hidden;
        color: #fff;
        white-space: nowrap;
    }
}

.modelScale_mirror {
    position: absolute;
    top: -30000px;
}

.modelScale_f {
    position: relative;
    .modelScale_f_b {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 100%);
        padding: 0 17%;
        display: flex;
        justify-content: center;
        transition: all .2s;
        @media screen and (max-height: 800px) {
            bottom: -8px;
        }
        .f_b_btn {
            flex: 1;
            min-width: 200px;
            height: 36px;
            font-size: 15px;
            line-height: 34px;
            text-align: center;
            border-radius: 12px;
            box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.16);
            letter-spacing: 5px;
            cursor: pointer;
            @media screen and (max-height: 800px) {
                height: 30px;
                line-height: 28px;
            }
        }
    }
    .modelScale_f_t {
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translate(-50%, -100%);
        background:#ffffff;
        margin: 0 auto;
        margin-bottom: 20px;
        display: flex;
        border: 1px solid #ff7532;
        // width: 340px;
        padding: 5px 10px;
        border-radius:26px;
        transition: all .2s;
        @media screen and (max-height: 800px) {
            top: -8px;
        }
        .anticon-exclamation-circle {
            color: #ff7532;
            margin-right: 5px;
        }
        .f_t_span_2 {
            white-space: nowrap;
            margin-left: 40px;
            color: var(--color-primary);
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
        .f_t_span_1 {
            color: #1d1b1b;
            white-space: nowrap;
        }
        .anticon-close {
            margin-left: 20px;
            cursor: pointer;
            color: #999;
            &:hover {
                color: #1d1b1b;
            }
        }
    }
}