@mixin modal_footer() {
    margin-top: 0;
    background: #FFFFFF;
    padding-top: 25px;
    padding-bottom: 28px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    > button {
        margin-inline-start: 0;
        padding: 0;
        width: 40%;
        max-width: 330px;
        height: 32px;
        border-radius: 12px;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            height: 30px;
            line-height: 30px;
        }
    }
    > button:nth-child(1) {
        margin-right: 20px;
        background-color: #EBF0FF;
        border-color: #EBF0FF;
        color: #000000;
        &:hover{
            background-color: darken($color: #EBF0FF, $amount: 2);
            border-color: darken($color: #EBF0FF, $amount: 2);
        }
    }
}

.__pasi_modal{
    width: calc(100% - 20px)!important;
    &.__pasi_modal_confirm{
        width: auto!important;
    }
    :global(.ant-modal-content) {
        position: relative;
        padding: 0;
        border-radius: 10px;
        overflow: hidden;
        :global(.ant-modal-header){
            position: relative;
            width: 100%;
            height: 40px;
            margin-bottom: 0;
            background: var(--color-98);
            :global(.ant-modal-title){
                position: absolute;
                left: 24px;
                top: 50%;
                transform: translate(0, -50%);
                height: 44px;
                line-height: 44px;
                color:#1d1b1b;
                font-size:16px;
                background-color: transparent;
            }
        }
        :global(.ant-modal-body){
            background: #FFFFFF;
        }
        :global(.ant-modal-footer){
            @include modal_footer;
        }
        :global(.ant-modal-close){
            width: 32px;
            height: 32px;
            top: 20px;
            right: 24px;
            transform: translate(0, -50%);
        }
    }
    :global(.ant-modal-body) > :global(.ant-modal-confirm-body-wrapper){
        :global(.ant-modal-confirm-body .ant-modal-confirm-paragraph){
            display: block;
            width: 100%;
            max-width: none;
        }
        :global(.ant-modal-confirm-btns){
            @include modal_footer;
            // padding-left: 80px;
            // padding-right: 80px;
            // button{
            //     width: 50%;
            // }
        }
    }
}

.__pasi_confirm_contentwrap{
    .__pasi_confirm_header{
        position: relative;
        width: 100%;
        height: 40px;
        margin-bottom: 0;
        background: var(--color-98);
        >span:first-child{
            position: absolute;
            left: 24px;
            top: 50%;
            transform: translate(0, -50%);
            height: 44px;
            line-height: 44px;
            color:#1d1b1b;
            font-size:16px;
        }
    }
    .__pasi_confirm_body{
        position: relative;
        max-width: 480px;
        min-width: 320px;
        margin: 32px 32px 20px;
        padding-left: 48px;
        &::after{
            content: "";
            display: block;
            width: 30px;
            height: 30px;
            background: url("~@assets/images/icon_warning.svg") center / cover no-repeat;
            position: absolute;
            left: 0;
            top: 0;
        }
        .__pasi_confirm_title{
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 18px;
        }
        .__pasi_confirm_content{
            font-size: 14px;
            line-height: 26px;
        }
    }
}
