.custom {
    width: 100%;
    :global(.ant-row) {
        margin-bottom: 5px;
    }
    .custom_p {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    textarea {
        margin-bottom: 5px;
    }
}
