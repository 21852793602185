.ctrlContainer{
    display: flex;
    flex-direction: column;
    align-items: center;

    .shiftTips{
        align-items: flex-start;
        width: 100%;
        margin-left: 0;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .shiftGif{
        margin-top: 10px;
        width: 260px;
        height: 260px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        @media screen and (max-width: 1200px) {
            width: 100%;
            height: 160px;
        }
        video{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .shiftContent{
        margin-top: 50px;
        li{
            display: block;
            position: relative;
            color: #1d1b1b;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 30px;
            padding-left: 30px;
            span{
                font-weight: bold;
            }
            &:nth-child(2){
                margin-bottom: 40px;
                @media screen and (max-width: 1200px) {
                    margin-bottom: 70px;
                }
            }
            &:nth-child(1)::before{
                content: '1';
                color: #FFFFFF;
            }
            &:nth-child(2)::before{
                content: '2';
                color: #FFFFFF;
            }
            &:nth-child(3)::before{
                content: '3';
                color: #FFFFFF;
            }
            &:before{
                position: absolute;
                left: 0;
                padding: 0 6px;
                border-radius: 3px;
                font-size: 12px;
                height: 20px;
                line-height: 20px;
                background:var(--color-primary);
                margin-right: 10px;
            }
        }
        .shiftContent_tips{
            position: absolute;
            bottom: -10px;
            margin-top: 10px;
            color: #bcbcbc;
            font-size: 12px;
            line-height: 1.5;
            letter-spacing: 0.2px;
            white-space: nowrap;
            transform-origin: left top;
            transform: translate(0, 100%);
            @media screen and (max-width: 1200px) {
                white-space: normal;
                transform: translate(0, 100%);
            }
        }  
    }

    .shiftReselect{
        margin-top: 100px;
        height: 36px;
        &:disabled{
            background-color: transparent;
        }
    }

    .shiftShape{
        margin-top: 20px;
        height: 36px;
        &:disabled{
            background-color: transparent;
        }
    }
}