.SelectModel {
    padding: 0 8px;
    .select_title{
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span:nth-child(1) {
            font-weight: 700;
            font-size: 14px;
        }
    }
    .select_group {
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 13px;
        .select_item{
            display: inline-block;
            padding: 4px 8px;
            min-width: 46px;
            border-radius: 8px;
            text-align: center;
            cursor: pointer;
            background: #F8F9FF;
            color: #35415C;
            &.selected{
                background: #E3E3F6;
                color: var(--color-primary);
            }
        }
        &.disabled{
            .select_item{
                cursor: not-allowed;
                background: #F7F8FF;
                color: #D7D7D7;
            }    
        }
    }
}