.UploadWrap{
    display: inline-block;
    width: 100%;
    height: 100%;
    :global(.ant-upload.ant-upload-drag){
        display: inline-block;
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        text-align: left;
    }
    :global(.ant-upload.ant-upload-btn){
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 0;
    }
    :global(.ant-upload-drag .ant-upload-drag-container){
        display: inline-block;
        width: 100%;
        height: 100%;
    }
    :global(.ant-upload-drag .ant-upload){
        padding: 0;
    }
}