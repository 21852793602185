.Container{
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    overflow: hidden;
}

.DeskWrap{
    position: relative;
    height: 100%;
    .ImgDesk{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: transparent;
        >img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        .ToolBox{
            position: absolute;
            width: auto;
            top: -10px;
            right: 0;
            transform: translate(0, -100%);
        }
    }
}