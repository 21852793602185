
.sceneItem{
    cursor: pointer;
    position: relative;
    width: calc((100% - 30px) / 3);
    border-radius: 12px;
    border: 1px dashed #C0CBF4;
    overflow: hidden;
    margin-right: 15px;
    &:hover{
        border-color: var(--color-primary);
    }
    &::after{
        content: "";
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }
    .sceneItemContent{
        position: absolute;
        background: rgba(235, 240, 255, 0.5);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #35415C;
        font-size: 12px;
        line-height: 20px;
        >span:nth-child(1){
            font-size: 16px;
            margin-bottom: 4px;
        }
    }
}

.CustomModelFormWrap{
    width: calc(100% - 64px);
    margin: 12px auto;
}

.ImageCard{
    cursor: pointer;
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 12px;
    overflow: hidden;
    img{
        object-fit: contain;
        background-color: #eeeeee;
    }
    &:hover{
        .del_btn{
            visibility: visible;
        }
    }
    .del_btn{
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 5px;
        display: block;
        width: 20px;
        height: 20px;
        z-index: 10;
        visibility: hidden;
        background: url('~@assets/images/Remove_Normal.svg') center / cover no-repeat;
        transition: background-image .2s;
        &:hover{
            background-image: url('~@assets/images/Remove_hover.svg')
        }
        &:active{
            background-image: url('~@assets/images/Remove_Press.svg')
        }
    }
}
.UploadWrap:global(.ant-upload-wrapper.ant-upload-picture-card-wrapper){
    position: relative;
    width: 120px;
    height: 120px;
    :global(.ant-upload.ant-upload-select){
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;
        overflow: hidden;
    }
    .UploadContent{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.UploadRemark{
    position: relative;
    border-radius: 20px;
    height: 342px;
    background: #F8F9FF;
    padding-top: 12px;
    .RightContent{
        width: calc(100% - 80px);
        height: 30px;
        margin: 0 auto 4px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span:nth-child(1) {
            font-size: 16px;
            color: #00D32A;
            margin-right: 12px;
        }
        span:nth-child(2){
           font-size: 12px;
           font-weight: 500;
           margin-right: 12px;
        }
        span:nth-child(3){
            font-size: 12px;
            color: #979797;
        }
    }
    .WrongContent{
        width: calc(100% - 80px);
        height: 30px;
        margin: 0 auto 4px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span:nth-child(1) {
            font-size: 16px;
            color: #FF2424;
            margin-right: 12px;
        }
        span:nth-child(2){
            font-size: 12px;
            font-weight: 500;
            margin-right: 12px;
        }
        span:nth-child(3){
            font-size: 12px;
            color: #979797;
        }
    }
    .RemarkList{
        width: calc(100% - 80px);
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        >div{
            position: relative;
            width: 100px;
            height: 100px;
            background: #FFFFFF;
            border-radius: 12px;
            overflow: hidden;
            >p{
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 24px;
                font-size: 12px;
                color: #FFFFFF;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0.8);
                span{
                    transform: scale3d(.8,.8,1);
                }
            }
        }
    }
    .Disclaimer{
        cursor: pointer;
        position: absolute;
        right: 40px;
        bottom: 12px;
        font-size: 12px;
        color: #979797;
        display: flex;
        justify-content: center;
        align-items: center;
        span:nth-child(2){
            margin-left: 6px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #EDEDF1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .arrow {
            display: block;
            width: 4px;
            height: 4px;
            border-top: 1px solid #8D8D8D;
            border-left: 1px solid #8D8D8D;
            transform: rotate(135deg);
            margin-right: 1px;
        }
    }
}