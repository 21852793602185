.DownloadMenu{
    padding: 8px;
    background-color: rgba(29, 27, 27, 0.8);
    border-radius: 12px;
}

.MenuItem{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // width: 123px;
    height: 26px;
    border-radius: 8px;
    color:#ffffff;
    font-size: 12px;
    line-height: 26px;
    padding: 0 8px;
    text-align: left;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .1);
    white-space: nowrap;
    cursor: pointer;
    img{
        width: 12px;
        margin-left: 4px;
    }
    &:not(:last-child){
        margin-bottom: 8px;
    }
    &:hover{
        background-color: rgba(235, 240, 255, 1);
        color:#1d1b1b;
    }
}

.TaskTools{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    width: calc(100% - 20px);
    margin: 0 auto;
    pointer-events: none;
    &.small{
        height: calc(28px * 2 + 10px);
        flex-wrap: wrap;
        // justify-content: flex-end;
    }
    .TaskToolsRoundIcons{
        flex: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .hdPreview{
        pointer-events: all;
        flex: none;
        cursor: pointer;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 72px;
        height: 28px;
        text-align: center;
        border-radius: 14px;
        font-size: 12px;
        line-height: 28px;
        background-color: hsla(0, 0%, 0%, .25);
        color: #FFFFFF;
    }
    .videoPlayer{
        flex: 1;
        pointer-events: all;
        display: flex;
        align-items: center;
        margin-right: 10px;
        .playBtn{
            display: block;
            width: 28px;
            height: 28px;
            font-size: 28px;
            border-radius: 50%;
            background-color: #FFFFFF;
            color: rgba(29, 27, 27, 0.6);
            margin-right: 10px;
        }
        .progress{
            flex: 1;
            position: relative;
            height: 4px;
            border-radius: 50px;
            border: none;
            overflow: hidden;
            display: flex;
            &::after{
                content: "";
                flex: 1;
                display: block;
                height: 100%;
                background-color: rgba(29, 27, 27, 0.9);
            }
            >div{
                flex: none;
                height: 4px;
                background-color: #FFFFFF;
            }
        }
    }
    .RoundIcon{
        pointer-events: all;
        position: relative;
        cursor: pointer;
        display: block;
        // width: 20%;
        // max-width: 28px;
        width: 28px;
        &:not(:last-child){
            margin-right: .08rem;
        }
        &::after{
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
        }
        transition: background-image .2s;
    }
    .share{
        background: url('~@assets/images/share_hover.svg') center / cover no-repeat;
        &.dark{
            background-image: url('~@assets/images/share_dark.svg');
            &:hover{
                background-image: url('~@assets/images/share_dark_hover.svg');
            }
            &:active{
                background-image: url('~@assets/images/share_dark_press.svg');
            }
        }
        &:hover{
            background-image: url('~@assets/images/share.svg');
        }
        &:active{
            background-image: url('~@assets/images/share_press.svg');
        }
    }
    .bookmark{
        background: url('~@assets/images/bookmark_hover.svg') center / cover no-repeat;
        &:hover{
            background-image: url('~@assets/images/bookmark.svg');
        }
        &:active{
            background-image: url('~@assets/images/bookmark_press.svg');
        }
        &.dark{
            background-image: url('~@assets/images/bookmark_dark.svg');
            &:hover{
                background-image: url('~@assets/images/bookmark_dark_hover.svg');
            }
            &:active{
                background-image: url('~@assets/images/bookmark_dark_press.svg');
            }
        }
        &.checked{
            background-image: url('~@assets/images/bookmark_checked_hover.svg');
            &:hover{
                background-image: url('~@assets/images/bookmark_checked.svg');
            }
            &:active{
                background-image: url('~@assets/images/bookmark_checked_press.svg');
            }
            &.dark{
                background-image: url('~@assets/images/bookmark_checked_dark.svg');
                &:hover{
                    background-image: url('~@assets/images/bookmark_checked_dark_hover.svg');
                }
                &:active{
                    background-image: url('~@assets/images/bookmark_checked_dark_press.svg');
                }
            }
        }
        
    }
    .aieditor{
        background: url('~@assets/images/aieditor_hover.svg') center / cover no-repeat;
        &:hover{
            background-image: url('~@assets/images/aieditor.svg');
        }
        &:active{
            background-image: url('~@assets/images/aieditor_press.svg');
        }
        &.dark{
            background-image: url('~@assets/images/aieditor_dark.svg');
            &:hover{
                background-image: url('~@assets/images/aieditor_dark_hover.svg');
            }
            &:active{
                background-image: url('~@assets/images/aieditor_dark_press.svg');
            }
        }
    }
    .download{
        background: url('~@assets/images/download_hover.svg') center / cover no-repeat;
        &:hover, &.hover{
            background-image: url('~@assets/images/download.svg');
        }
        &:active{
            background-image: url('~@assets/images/download_press.svg');
        }
        &.dark{
            background-image: url('~@assets/images/download_dark.svg');
            &:hover{
                background-image: url('~@assets/images/download_dark_hover.svg');
            }
            &:active{
                background-image: url('~@assets/images/download_dark_press.svg');
            }
        }
    }
}