.textTag {
    div {
        display: flex;
        .title_ {
            width: 32px;
            height: 26px;
            line-height: 26px;
            font-size: 12px;
        }
    }
    p {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: calc(100% - 32px);
        span{
            margin-bottom: 10px;
            cursor: pointer;
            display:inline-block;
            padding: 4px 8px;
            border-radius: 8px;
            background:#ECEBEB;
            color: #4f4f4f;
            margin-right: 8px;
            box-sizing: content-box;
            font-size: 11px;
            line-height: 16px;
            height: 16px;
        }
    }
    .select_sty {
        background-color: #EBF0FF!important;
        color: #2237F4!important;
    }
}

