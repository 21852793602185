.myProject {
    padding: 20px .6rem;
    display: flex;
    flex-direction: column;
    .m_title {
        flex: none;
        display: flex;
        justify-content: space-between;
        .focused{
            width: 270px;
            &:global(.ant-input-affix-wrapper){
                // width: 115px;
                transition: width .2s linear;
                font-size: 12px;
                height: 28px;
                :global(>input.ant-input){
                    padding-left: 5px;
                }
            }
        }
        .options_opts {
            margin-left: 20px;
            display: flex;
            justify-content: flex-end;
            button {
                // min-width: 70px;
                font-size: 12px;
                height: 28px;
                line-height: 27px;
                padding: 0 20px;
                border-radius: 8px;
                &:not(:first-child){
                    margin-left: 10px;
                }
            }
        }
        .m_title_c {
            color:#1d1b1b;
            font-size:16px;
            display: flex;
            align-items: center;
            .m_menu {
                height: 28px;
                font-size:16px;
                margin-right: 20px;
                :global(.ant-dropdown-trigger) {
                    font-size:15px;
                    height: 100%;
                }
                .dropdown_action {
                    vertical-align: 2;
                    font-size: 13px;
                    color: #666;
                    transform: scaleY(.8);
                }
            }            
        }
    }
    .m_box {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        overflow-y: auto;
        padding: 14px 0;
        margin: 10px 0;
        &::-webkit-scrollbar {
            width: .05rem;
        }
        &::-webkit-scrollbar-track {
            background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #e8e9ee;
            border-radius: 10px;
        }
        
        .m_box_item_ {
            position: relative;
            width: calc((100% - 3rem - 2px) / 6);
            margin-right: 0;
            transition: width 250ms, margin 250ms;
            &:not(:nth-child(6n)) {
                margin-right: .6rem;
            }
            &:nth-child(n):nth-child(-n+6){
                margin-bottom: 120px;
            }
            &:nth-child(n+7):nth-child(-n+12) {
                margin-bottom: 70px;
            }
            @media screen and (max-width: 1300px) {
                width: calc((100% - 1.8rem - 2px) / 4);
                &:not(:nth-child(6n)) {
                    margin-right: 0;
                }
                &:not(:nth-child(4n)) {
                    margin-right: .6rem;
                }
                &:nth-child(n):nth-child(-n+6){
                    margin-bottom: 90px;
                }
                &:nth-child(n+7):nth-child(-n+12) {
                    margin-bottom: 90px;
                }
                &:nth-child(n+9):nth-child(-n+12) {
                    margin-bottom: 80px;
                }
            }
            &::after{
                content: "";
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: 100%;
            }
            .m_box_create {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border:1px dashed var(--color-primary);
                border-radius: 16px;
                cursor: pointer;
                background:#f8f9fd;
                .m_box_create_ {
                    color:var(--color-primary);
                    height: 100%;
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .m_box_create_box {
                        img {
                            width: .6rem;
                            margin: 0 auto;
                        }
                        p{
                            font-size: .16rem;
                            margin-top: .1rem;
                            @media screen and (max-width: 1440px) {
                                font-size: 12px;
                            }
                        }
                        @media screen and (max-width: 1300px) {
                            img{ width: 0.8rem; }
                        }
                    }
                }
            }
        }
    }
    .pagination {
        flex: none;
        text-align: right;
        margin-right: 0.5rem;
        margin-top: 0;
    }
}