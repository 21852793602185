.Container{
    padding: 0 8px;
    .Content{
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 13px;
        .SelectionItemBox{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
        }
    }
    .empty_wrap{
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.sceneItem {
    width: calc((100% - 30px) / 3);
    &::after{
        content: "";
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 166.86%;
    }
    &:not(:nth-child(3n)) {
        margin-right: 15px;
    }
    &:not(:nth-child(-n+3)){
        margin-top: 12.5px;
    }
    border: 2px solid #fff;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    .sceneItemImg {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }
}
.uploadWrap{
    cursor: pointer;
    border: 1px solid #D3DBFF!important;
    &:hover{
        border: 1px solid var(--color-primary)!important;
    }
    :global(.ant-upload.ant-upload-select),
    :global(.ant-upload.ant-upload-drag){
        position: absolute;
        width: 100%;
        height: 100%;
        border: none;
    }
    .addTrigger{
        width: 100%;
        height: 100%;
        position: relative;
        .addTriggerContent{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            > span{
                font-size: 24px;
                color: var(--color-primary);
                margin-bottom: 10px;
            }
            > .Title{
                font-size: 12px;
                color: var(--color-primary);
                margin-bottom: 20px;
            }
            > .UploadProgress{
                position: absolute;
                bottom: 26px;
                font-size: 12px;
                color: var(--color-primary);
            }
            > .Requirement{
                position: absolute;
                bottom: 4px;
                font-size: 12px;
                color: #D3DBFF;
                &:hover{
                    color: var(--color-primary);
                }
            }
            .cornerMark{
                position: absolute;
                left: 0;
                top: 0;
                width: 50px;
                height: 16px;
                font-size: 12px;
                line-height: 16px;
                color: #FFFFFF;
                background: linear-gradient(95.16deg, #393EFD -7.54%, #8C29FE 93.45%);
                border-bottom-right-radius: 12px;
                span{
                    display: inline-block;
                    transform: scale3d(.7, .7, 1);
                }
            }
        }
    }
}

.UploadExample{
    padding: 40px;
    > div:not(:last-child){
        margin-bottom: 40px;
    }
    .UploadExampleTitle{
        border-radius: 12px;
        font-weight: bold;
        margin-bottom: 35px;
    }
    .UploadExampleList{
        display: flex;
        .UploadExampleItem{
            max-width: 133px;
            width: (calc(100% / 5));
            &:not(:last-child){
                margin-right: 20px;
            }
            >div{
                position: relative;
                width: 100%;
                height: auto;
                &::after{
                    content: "";
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-bottom: 130%;
                }
                img:first-child{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
                img:last-child{
                    position: absolute;
                    right: 8%;
                    bottom: 5%;
                    width: 15%;
                }
            }
            
            span{
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
}