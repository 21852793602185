.PersonalCenter {
    height: 100%;
    padding: 0 20px 20px;
    background-color: #F5F6FC;
    display: flex;
    flex-direction: column;
    .PersonalCenter_ {
        flex: 1;
        position: relative;
        display: flex;
        box-shadow:2px 2px 20px rgba(221, 231, 255, 0.5);
        border-radius: 10px;
        height: 100%;
        margin-top: 20px;
        background-color: #F5F6FC;
        .pe_left {
            flex: none;
            position: relative;
            background-color: #fff;
            border-radius: 12px 0 0 12px;
            width: 250px;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-content: flex-start;
            flex-wrap: wrap;
            min-width: 200px;
            height: 100%;
            img {
                width: 100px;
                height: 100px;
            }
            .change_record {
                position: absolute;
                bottom: 20px;
                left: 20px;
                width: calc(100% - 40px);
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                :global(.ant-btn) {
                    font-size: 10px;
                }
            }
            // .plan_p {
            //    width: 1.8rem;
            //    height: .36rem;
            //    background-color: #60342A;
            //    color: #fff;
            //    line-height: .36rem;
            //    text-align: center;
            //    margin-top: 18px;
            //    border-radius: 12px;
            //    margin-bottom: 20px;
            // }
            .messag_box {
                width: 100%;
                flex-wrap: nowrap;
                margin-bottom: 20px;
                .messag_box_p1 {
                    color: #9194A6;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    .more{
                        display: inline-block;
                        width: 16px;
                        height: 14px;
                        margin-left: 12px;
                        background: url('~@assets/images/more.svg') center / contain no-repeat;
                        cursor: pointer;
                    }
                }
                .messag_box_p2 {
                    margin-top: 12px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                    img {
                        height: 10px;
                        width: 10px;
                        margin-left: 5px;
                    }
                    > span:nth-child(1) {
                        font-weight: bold;
                        width: calc(100% - 80px);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 16px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: flex-start;
                    }
                    > span.ellipsis:nth-child(1){
                        font-weight: bold;
                        width: calc(100% - 80px);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 16px;
                        display: block;
                    }
                    > span:nth-child(2) {
                        display: inline-block;
                        background-color: #EFF2FD;
                        color: #393EFDB2;
                        padding: 0 20px;
                        text-align: center;
                        cursor: pointer;
                        font-size: 12px;
                        line-height: 24px;
                        border-radius: 30px;
                    }
                   
                }
            }
            :global(.ant-divider-horizontal) {
                margin: 0 0 20px 0;
            }
        }
        .pe_right {
            height: 100%;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: .05rem;
            }
            &::-webkit-scrollbar-track {
                background-color: #fff;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #e8e9ee;
                border-radius: 10px;
            }
            flex-grow: 1;
            width: 98%;
            // max-width: 1280px;
            margin: 0 auto;
            padding: 0 20px;
      
            .pe_right_top {
                display: flex;
                img {
                    width: 80px;
                    height: 80px;
                    margin-right: .8rem;
                    transition: margin-right .2s;
                }
                .pe_right_rig {
                    flex: 1;
                }
                @media screen and (max-width: 1200px) {
                    img {
                        margin-right: .4rem;
                    }
                }
            }
            .MemberPackageWrap{
                margin: 20px 0 20px 0;
            }
            .title_r {
                display: flex;
                color: #4f4f4f;
                font-size: 16px;
                img {
                    width:15px;
                    height:16px;
                    margin-top: 6px;
                    margin-right: 8px;
                }
            }
            .pe_info{
                margin: 20px 0;
                display: flex;
                align-items: flex-start;
                .pe_info_box{
                    position: relative;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    transition: all .2s;
                    &:nth-child(1){ width: 300px; flex: none; }
                    &:nth-child(2){ flex: 1; }
                    &:nth-child(3){ flex: 1; }
                    &:not(:last-child){
                        border-right: 1px solid #e8e9ee;
                    }
                    &:nth-child(1){
                        p > span.label{
                            width: 80px;
                        }
                    }
                    &:nth-child(2){
                        p > span.label{
                            width: 70px;
                        }
                        p > span.value{
                            max-width: 200px;
                        }
                    }
                    &:nth-child(3){
                        p > span.label{
                            width: 100px;
                        }
                        p > span.value{
                            max-width: 200px;
                        }
                    }
                    @media screen and (max-width: 1200px) {
                        &:nth-child(1){ width: 30%; flex: none; }
                    }
                    @media screen and (max-width: 1200px) {
                        &:nth-child(2){
                            p > span.value{
                                max-width: 150px;
                            }
                        }
                    }
                    .info_wrap{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        width: fit-content;
                        margin: 0 auto;
                    }
                    p{
                        &:not(:last-child){
                            margin-bottom: 26px;
                        }
                        > span{
                            display: inline-block;
                            text-align: left;
                            font-size: 16px;
                            vertical-align: bottom;
                            line-height: 24px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        > span.label {
                            font-weight:300;
                            color:#4f4f4f;
                            font-size:16px;
                            text-align: right;
                        }
                        > span.value {
                            font-weight:500;
                            color:#1d1b1b;
                            font-size:16px;
                        }
                        > span.more{
                            display: inline-block;
                            width: 16px;
                            height: 24px;
                            line-height: 24px;
                            background: url('~@assets/images/more.svg') center / contain no-repeat;
                            cursor: pointer;
                        }
                        > span:not(:last-child){
                            margin-right: 15px;
                        }
                        > span.interactive {
                            // text-decoration: underline;
                            // color: #326fff;
                            // font-size: 12px;
                            cursor: pointer;
                            color:#979797;
                            font-size:14px;
                        }
                        @media screen and (max-width: 1200px) {
                            > span:not(:last-child){
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
            .pay_box {
                display: flex;
                justify-content: space-between;
                margin-top: 40px;
                margin-bottom: 40px;
                .pay_item {
                    position: relative;
                    width: calc((100% - 30px) / 4);
                    max-width: 272px;
                    background:#ffffff;
                    box-shadow:0px 0px 5px #d8d9e1;
                    border-radius: 15px;
                    transition: all 0.2s;
                    &:not(:last-child){
                        margin-right: 10px;
                    }
                    &::after{
                        content: '';
                        display: block;
                        width: 100%;
                        height: 0;
                        padding-bottom: 126.68%;
                    }
                    &.free{
                        .title, .pice{
                            color:#4f4f4f;
                        }
                    }
                    &.basic{
                        .title, .pice{
                            color:var(--color-primary);
                        }
                    }
                    &.professional{
                        .title, .pice{
                            // color:#865739;
                            color:var(--color-primary);
                        }
                    }
                    &.ultimate{
                        .title, .pice{                          
                            // color:#634119;
                            color:var(--color-primary);
                        }
                    }
                    .pay_item_ {
                        position: absolute;
                        width: calc(100% - 20px);
                        height: calc(100% - 20px);
                        padding: 7px 16px;
                        margin: 10px 10px;
                        left: 0;
                        top: 0;
                        transition: all 0.2s;
                        border-radius: 10px;
                        border: 1px solid #F4F4F4;
                        .title {
                            font-size: 16px;
                        }
                        .pice {
                            line-height: 1;
                            margin-top: 40px;
                            font-size: 36px;
                            font-weight: bold;
                            transition: all 0.2s;
                            span:not(:nth-child(2)){
                                position: relative;
                                font-size: 24px;
                            }
                            span:nth-child(1){
                                top: -1px
                            }
                            span:nth-child(3){
                                top: -3px;
                            }
                        }
                        ul {
                            margin-top: 32px;
                            transition: all 0.2s;
                            li {
                                white-space: nowrap;
                                font-size: 12px;
                                line-height: 1;
                                transition: all 0.2s;
                                &:not(:last-child){
                                    margin-bottom: 12px;
                                }
                            }
                        }
                        // .pay_btn{
                        //     cursor: pointer;
                        //     position: absolute;
                        //     left: 50%;
                        //     bottom: 17px;
                        //     transform: translate(-50%, 0);
                        //     width: calc(100% - 16px - 16px);
                        //     border-radius: 30px;
                        //     background-color: #000;
                        //     overflow: hidden;
                        //     @media screen and (max-width: 1200px){
                        //         width: calc(100% - 30px);
                        //     }
                        //     & > p{
                        //         position: absolute;
                        //         left: 50%;
                        //         top: 50%;
                        //         transform: translate(-50%, -50%);
                        //         text-align: center;
                        //         display: flex;
                        //         flex-direction: column;
                        //         justify-content: center;
                        //         align-items: center;
                        //         z-index: 1;
                        //         & > span:first-child{
                        //             display: inline-block;
                        //             font-size: 18px;
                        //             line-height: 1;
                        //             margin-bottom: 2px;
                        //         }
                        //         & > span:nth-child(2){
                        //             display: inline-block;
                        //             font-size: 12px;
                        //             line-height: 1;
                        //         }
                        //         @media screen and (max-width: 1200px){
                        //             & > span:first-child{ font-size: 16px; }
                        //         }
                        //         @media screen and (max-width: 1080px) {
                        //             & > span:first-child{ font-size: 15px; }
                        //         }
                        //     }
                        //     &::after{
                        //         content: '';
                        //         display: block;
                        //         width: 100%;
                        //         height: 0;
                        //         padding-bottom: 18.3%;
                        //     }
                        //     &:hover::after{
                        //         mix-blend-mode:hard-light;
                        //     }
                        //     &:active::after, &.actived::after{
                        //         mix-blend-mode:multiply;
                        //     }
                        //     &.free, &.basic{
                        //         color:#ffffff;
                        //         background-color: #00A0FF;
                        //         &:hover{
                        //             background-color: #00AAFF;
                        //         }
                        //         &:active{
                        //             background-color: var(--color-primary);
                        //         }
                        //         // background:linear-gradient(165.04deg,#00a0ff 0%,#326fff 100%);
                        //         // &::after{
                        //         //     background:linear-gradient(165.04deg,#00a0ff 0%,#326fff 100%);
                        //         // }
                        //     }
                        //     &.professional{
                        //         color:#ffffff;
                        //         background-color: #00A0FF;
                        //         &:hover{
                        //             background-color: #00AAFF;
                        //         }
                        //         &:active{
                        //             background-color: var(--color-primary);
                        //         }
                        //         // background:linear-gradient(154.12deg,#f7ddc7 0%,#b48f72 100%);
                        //         // &::after{
                        //         //     background:linear-gradient(154.12deg,#f7ddc7 0%,#b48f72 100%);
                        //         // }
                        //     }
                        //     &.ultimate{
                        //         color:#ffffff;
                        //         background-color: #00A0FF;
                        //         &:hover{
                        //             background-color: #00AAFF;
                        //         }
                        //         &:active{
                        //             background-color: var(--color-primary);
                        //         }
                        //         // color:#ffecd5;
                        //         // background:linear-gradient(156.16deg,#4e4127 0%,#180e03 100%);
                        //         // &::after{
                        //         //     background:linear-gradient(156.16deg,#4e4127 0%,#180e03 100%);
                        //         // }
                        //         // &:hover::after{
                        //         //     mix-blend-mode:screen;
                        //         // }
                        //         // &:active::after, &.actived::after{
                        //         //     mix-blend-mode:multiply;
                        //         // }
                        //     }
                        // }
                    }
                    @media screen and (max-width: 1200px) {
                        max-width: 260px;
                        .pay_item_{
                            width: calc(100% - 12px);
                            height: calc(100% - 12px);
                            padding: 11px 9px;
                            margin: 6px 6px;
                            .pice {
                                margin-top: 20px;
                            }
                        }
                    }
                    @media screen and (max-width: 1080px) {
                        max-width: 220px;
                        .pay_item_{
                            padding: 10px 15px;
                            width: 100%;
                            height: 100%;
                            margin: 0;
                            .pice {
                                margin-top: 10px;
                            }
                            ul{
                                margin-top: 15px;
                                li{
                                    white-space: normal;
                                    line-height: 1.5;
                                    &:not(:last-child){
                                        margin-bottom: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
                .pay_item:hover, .pay_item.actived {
                    box-shadow:0px 4px 12px #bac0db;
                }
            }
        }
    }
    :global(.ant-table-wrapper .ant-table-thead >tr>th),
    :global(.ant-table-wrapper .ant-table-thead >tr>td) {
        background-color: #efefef;
    }
}

.back_record {
    width: 70px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1D1B1B;
    border: 1px solid #B7B7B7;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 6px;
    &:hover {
        background-color: #F2F2F2;
    }
}

.order_top {
    padding: 15px 32px;
    background: linear-gradient(180deg, #D6E7FF -12.35%, #E6F1FF 100%);
    border-radius: 12px ;
    display: flex;
    align-items: center;
    span:nth-child(1), span:nth-child(4) {
        margin-right: .3rem;
    }
    span:nth-child(3), span:nth-child(6) {
        font-size: 24px;
        font-weight: bold;
        margin-left: .1rem;
    }
    span:nth-child(4) {
        margin-left: 1rem;
    }
    span:nth-child(7) {
        display: inline-block;
        width: 2.5rem;
        height: 40px;
        background-color: #64A6FF;
        margin-left: auto;
        text-align: center;
        color: #fff;
        line-height: 40px;
        border-radius: 12px;
        cursor: pointer;
        &:hover {
            opacity: .8;
        }
    }
}