.Container{
    padding: 16px 24px 0;
    .Title{
        font-size: 16px;
        margin-bottom: 16px;
    }
    .Content{
        display: flex;
        justify-content: space-between;
        >div{
            position: relative;
            width: calc((100% - 17px) / 2);
            border-radius: 12px;
            &::after{
                content: "";
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: 100%
            }
        }
        .ReplicaOf{
            border: 1px solid #EBF0FF;
            overflow: hidden;
            :global(.ant-image){
                position: absolute;
                img{
                    object-fit: contain;
                }
            }
        }
        .ReplicaUpload{
        }
    }
    .Footer{
        width: calc((100% - 17px) / 2);
        display: flex;
        justify-content: center;
        margin-left: calc((100% - 17px) / 2);
        margin-top: 16px;
        .create_styl {
            position: relative;
            height: 18px;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .use_point {
                display: flex;
                align-items: baseline;
                img {
                    margin-left: 12px;
                    height: 10px;
                    width: 10px;
                }
            }
            .num_btn {
                position: relative;
                display: inline-block;
                background-color: #EFF2FD;
                user-select: none;
                width: 18px;
                height: 18px;
                line-height: 18px;
                text-align: center;
                border-radius: 2px;
                cursor: pointer;
                margin: 0 5px;
                opacity: 1;
                &:hover {
                    opacity: 0.8;
                }
                &.disabled{
                    background-color: #F0F0F0;
                    color: #CBCBCB;
                }
            }
            &>span:nth-child(6) {
                position: absolute;
                right: 0;
                font-size: 12px;
                line-height: 18px;
                span {
                    margin-left: 5px;
                    color: #000000;
                }
            }
        }
    }
}

.EmptyUpload:global(.ant-upload-wrapper.ant-upload-picture-card-wrapper){
    position: absolute;
    width: 100%;
    height: 100%;
    :global(.ant-upload.ant-upload-select){
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;
        overflow: hidden;
    }
    .EmptyUploadContent{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p:nth-child(1){
            width: 208px;
            font-size: 16px;
            color: #CBCBCB;
            margin: 0 auto;
        }
        button{
            width: 228px;
            height: 44px;
            font-size: 20px;
            margin: 36px 0 12px;
        }
        p:nth-child(3){
            font-size: 12px;
            color: #CBCBCB;
            margin: 0 auto;
            span{
                display: block;
                text-align: center;
            }
        }
    }
}

.UploadList{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    .UploadItem{
        position: relative;
        width: calc((100% - 25px) / 2);
        &::after{
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
        }
    }
}


.UploadContent{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    :global(.ant-image){
        img{
            object-fit: contain;
        }
    }
    &:hover .del_btn{
        visibility: visible;
    }
    .del_btn {
        position: absolute;
        right: 5px;
        top: 5px;
        display: block;
        width: 20px;
        height: 20px;
        z-index: 10;
        visibility: hidden;
        background: url('~@assets/images/Remove_Normal.svg') center / cover no-repeat;
        transition: background-image .2s;
        &:hover{
            background-image: url('~@assets/images/Remove_hover.svg')
        }
        &:active{
            background-image: url('~@assets/images/Remove_Press.svg')
        }
    }
}
