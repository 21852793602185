.PhotoProjectDetail {
    display: flex;
    flex-direction: column;
    color:#1d1b1b;
    background: #fff;
    height: 100%;
    box-shadow:2px 2px 20px rgba(221, 231, 255, 0.5);
    border-radius: 12px;
    overflow: auto auto;
    .top{
        background-color: #FFF;
        // position: sticky;
        // top: 0;
        width: 100%;
        height: 68px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px .4rem;
        z-index: 20;
        .head {
            width: 50%;
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            .project-name{
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                margin-left: 20px;
                width: 80%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .options_select{
            min-width: 70px;
            border-radius: 8px;
            // font-size: 12px;
            // line-height: 22px;
        }
        .options_opts{
            flex: none;
            button {
                // min-width: 70px;
                margin-left: 10px;
                font-size: 12px;
                height: 28px;
                line-height: 27px;
                padding: 0 20px;
                border-radius: 8px;
            }
        }
    }
    .content{
        display: flex;
        padding: 0 .4rem .4rem;
        height: 100%;
    }
    .task_content_spin{
        height: calc(100% - 68px);
        .ant-spin-container{
            height: 100%;
        }
    }
}